<router-outlet></router-outlet>
<div class="grid" *ngIf="page">
  <div class="col-12 card" *ngIf="routes">
    <p-panel header="Filters">
      <tj-route-list-filter [routeFilter]="routeFilter" [page]="page"></tj-route-list-filter>
    </p-panel>
    <p-toolbar styleClass="my-4">
      <ng-template pTemplate="left">
        <p-dropdown [showClear]="true" (onChange)="setBatchAction($event)" [options]="batchActions"
                    placeholder="Select an action"></p-dropdown>
      </ng-template>

      <ng-template pTemplate="right">
        <button pButton pRipple label="Create new route" icon="pi pi-plus" class="p-button-success mr-2 mb-2"
                [routerLink]="['/routes', 'new']"></button>
      </ng-template>
    </p-toolbar>

    <tj-route-list [routes]="routes"
                   (onView)="showRoute($event)"
                   (onSort)="sort($event)"></tj-route-list>
    <p-paginator [rows]="env.rowsPerPage[0]"
                 [rowsPerPageOptions]="env.rowsPerPage"
                 [totalRecords]="page.totalElements"
                 (onPageChange)="onGoToPage($event)"></p-paginator>
  </div>
</div>
