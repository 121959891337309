<div class="flex flex-wrap">
  <div *ngFor="let filter of ticketFilter | keys" class="mr-2 mb-2">
    <ng-container [ngSwitch]="filter.key">
      <p-chip *ngSwitchCase="'printed'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="Printed"></p-chip>
      <p-chip *ngSwitchCase="'deleted'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="Deleted"></p-chip>
      <p-chip *ngSwitchCase="'private'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="PRIVATE"></p-chip>
      <p-chip *ngSwitchCase="'privateRegistered'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="Private - 3rd Party/None Acct"></p-chip>
      <p-chip *ngSwitchCase="'createDate'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}"></p-chip>
      <p-chip *ngSwitchCase="'serviceDate'" [removable]="true" (onRemove)="onRemoveChip(filter.key)"
              label="{{filterNames[filter.key]}}: {{(filter.value.split('|')[0] | date:environment.dateFormat) + ' - ' + (filter.value.split('|')[1] | date:environment.dateFormat)}}">
      </p-chip>
      <p-chip *ngSwitchCase="'serviceDateBefore'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}"></p-chip>
      <p-chip *ngSwitchCase="'status'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="{{filterNames[filter.key]}}: {{getStatusValue(filter.value)}}"></p-chip>
      <p-chip *ngSwitchCase="'contactDate'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}"></p-chip>
      <p-chip *ngSwitchCase="'partnerId'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="{{filterNames[filter.key]}}: {{partnersMap[filter.value].name}}"></p-chip>
      <p-chip *ngSwitchCase="'technicianId'" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="{{filterNames[filter.key]}}: {{techniciansMap[filter.value].name}}"></p-chip>
      <ng-container *ngSwitchCase="'tags'">
        {{filterNames[filter.key]}}:
        <p-chip *ngFor="let tag of tags" [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="{{tag.label}}"></p-chip>
      </ng-container>
      <p-chip *ngSwitchDefault [removable]="true" (onRemove)="onRemoveChip(filter.key)" label="{{filterNames[filter.key]}}: {{filter.value}}"></p-chip>
    </ng-container>
  </div>
</div>
