
import { Injectable } from '@angular/core';
import { ApiService } from '../../../api/service/api.service';
import { ConfigService } from '../../../core/services';
import { BehaviorSubject, Observable, Subject } from 'rxjs';
import { ClientSignture } from '../../../api/model/ticket/client-signature';
import { Ticket } from '../../../api/model/Ticket.model';


@Injectable()
export class TicketUpdateCheckService {

  private readonly UPDATE_INTERVAL: number = 15000;

  private signature$: Subject<ClientSignture> = new Subject<ClientSignture>();

  private ticketChanged$: Subject<boolean> = new Subject<boolean>();

  private readonly ticketSignature: Observable<ClientSignture> = this.signature$.asObservable();

  private readonly ticketChanged: Observable<boolean> = this.ticketChanged$.asObservable();

  private timeInterval: ReturnType<typeof setInterval>;

  private latestSignature: ClientSignture;

  private mySignature: ClientSignture;

  constructor(private api: ApiService,
    private configService: ConfigService) {
  }


  public initTicketUpdateTracking(ticket: Ticket) {
    this.mySignature = this.configService.getClientSignature();

    this.ticketSignature.subscribe((actualTicketSignature: ClientSignture) => {

      // set initial signature
      if (!this.latestSignature) {
        this.latestSignature = actualTicketSignature;
        return;
      }

      // Was the ticket changed?
      if (!actualTicketSignature.isSame(this.latestSignature)) {

        // Was the ticket changed by the same client?
        if (!this.mySignature.isSameClient(actualTicketSignature)) {
          this.latestSignature = actualTicketSignature;
          this.ticketChanged$.next(true);
        }
      }
    });

    this.timeInterval = setInterval(() => {
        this.loadLastTicketSignature(ticket.id);
    }, this.UPDATE_INTERVAL);

    return this.ticketChanged;
  }

  private loadLastTicketSignature(id: number): void {
    this.api.ticket.getLastSignature(id)
      .subscribe((value: ClientSignture) => {
        this.signature$.next(value);
      }, (err) => {
        console.log('Cannot load ticket signature. Checks will be cancelled.', err);
        clearInterval(this.timeInterval);
      });
  }

  destroy() {
    clearInterval(this.timeInterval);
  }
}
