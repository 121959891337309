<div class="grid ">
  <div class="col-12">
    <p-toolbar>
      <div class="p-toolbar-group-left"></div>
      <div class="p-toolbar-group-right">
        <button pButton pRipple (click)="onCreateNewTechnician()" icon="pi pi-plus" class="p-button-success" label="New technician"></button>
      </div>
    </p-toolbar>
  </div>
  <div class="col-12">
    <tj-technician-search [filter]="searchFilter"
                          (selectTechnician)="onSelectTechnician($event)"></tj-technician-search>
  </div>
  <div *ngIf="page" class="lg:col-4 sm:col-12">
    <div class="grid">
      <div class="col-12">
        <tj-technician-list (onSort)="sort($event)"
                            (selectTechnician)="onSelectTechnician($event)"
                            [technicians]="technicians"></tj-technician-list>
      </div>
      <div class="col-12">
        <p-paginator (onPageChange)="onGoToPage($event)"
                     [rows]="env.rowsPerPage[0]"
                     [rowsPerPageOptions]="env.rowsPerPage"
                     [totalRecords]="page.totalElements"></p-paginator>
      </div>
    </div>
  </div>

  <div class="lg:col-8 sm:col-12">
    <ng-container *ngIf="selectedTechnician">
      <tj-technician-edit (onSave)="onSave($event)"
                          [technician]="selectedTechnician"></tj-technician-edit>
    </ng-container>
    <ng-container *ngIf="!selectedTechnician">
      <p class="text-center">No technician selected</p>
    </ng-container>
  </div>
</div>
