<div class="card" [formGroup]="currentFormGroup">
  <div *ngIf="paymentStep === paymentSteps.INITIAL">
    <div class="flex flex-wrap">
      <div class="col-12 lg:col-6">
        <div class="flex flex-wrap justify-content-between">
          <div class="col-12 lg:col-4">
            <tj-field label="Net amount" [control]="fcNetAmount">
              <tj-money-input formControlName="netAmount"></tj-money-input>
            </tj-field>
          </div>
          <div class="col-12 lg:col-8 grid">
            <div class="col-5 align-content-center">
              <p-checkbox
                name="applyTax"
                formControlName="applyTax"
                binary="true"
                label="Apply tax"
              ></p-checkbox>
            </div>
            <div class="col-6 col-offset-1">
              <tj-field label="Tax rate" [control]="fcTaxRate">
                <tj-percentage-input
                  [disabled]="fcApplyTax.value === false"
                  formControlName="taxRate"
                  placeholder="0"
                ></tj-percentage-input>
              </tj-field>
            </div>
            <div class="col-5 align-content-center">
              <p-checkbox
                name="applyCCFee"
                formControlName="applyCCFee"
                binary="true"
                label="Apply credit card fee"
              ></p-checkbox>
            </div>
            <div class="col-6 col-offset-1">
              <tj-field label="Credit card fee" [control]="fcCCFeeRate">
                <tj-percentage-input formControlName="ccFeeRate"
                                     [disabled]="fcApplyCCFee.value === false"></tj-percentage-input>
              </tj-field>
            </div>
          </div>

          <div class="col-12 grid">
            <div class="col-4">
              <tj-field label="Paying for" [control]="fcNetTerm">
                <p-dropdown [options]="itemTypes"
                            formControlName="itemType"></p-dropdown>
              </tj-field>
            </div>
            <div class="col-8 lg:col-5">
              <tj-field label="Number" [control]="fcInvoice">
                <input
                  pInputText
                  autocomplete="off"
                  type="text"
                  formControlName="invoiceId"
                />
              </tj-field>
            </div>
          </div>

          <div class="col-12">
            <tj-field label="Description" [control]="fcDescription">
              <input
                pInputText
                autocomplete="off"
                type="text"
                formControlName="description"
              />
            </tj-field>
          </div>

          <div class="col-12 lg:col-6">
            <tj-field label="Customer Name" [control]="fcCustomerName">
              <input
                pInputText
                autocomplete="off"
                type="text"
                formControlName="customerName"
              />
            </tj-field>
          </div>

          <div class="col-12 lg:col-6">
            <tj-field label="Email" [control]="fcEmail">
              <input
                pInputText
                autocomplete="off"
                type="email"
                formControlName="email"
              />
            </tj-field>
          </div>

          <div class="col-12">
            <tj-field label="Address" [control]="fcAddress">
              <input
                pInputText
                autocomplete="off"
                type="text"
                formControlName="address"
              />
            </tj-field>
          </div>
        </div>
      </div>
      <div class="col-12 lg:col-6">
        <div>
          <p-tabView [(activeIndex)]="paymentMethodIndex">
            <p-tabPanel>
              <ng-template pTemplate="header">
                <span (click)="initPayByCreditCard()">Pay by credit card</span>
              </ng-template>
              <div>
                <div id="checkout-form">
                  <!-- Containers for Card Fields hosted by PayPal -->
                  <div id="card-name-field-container"></div>
                  <div id="card-number-field-container"></div>
                  <div id="card-expiry-field-container"></div>
                  <div id="card-cvv-field-container"></div>
                </div>
              </div>
            </p-tabPanel>
            <p-tabPanel>
              <ng-template pTemplate="header">
                <span (click)="initVaultId()">Charge saved card / vault id</span>
              </ng-template>
              <div>
                <div class="py-3">
                  <input
                    pInputText
                    type="text"
                    id="vault-id"
                    name="vault-id"
                    [(ngModel)]="vaultId"
                    [ngModelOptions]="{standalone: true}"
                    autocomplete="off"
                    placeholder="Vault ID"
                  />
                </div>
              </div>
            </p-tabPanel>
          </p-tabView>
        </div>
        <div class="flex flex-column align-items-end mr-4">
          <div class="flex justify-content-start align-items-center mb-2">
            <span class="font-semibold">Net Amount: </span>
            <span class="text-right w-6rem">{{ paymentDetails.netAmount | currency : "USD" }}</span>
          </div>
          <div
            class="flex justify-content-start align-items-center mb-2"
            *ngIf="paymentDetails.applyTax"
          >
            <span class="font-semibold">Tax rate: </span>
            <span class="text-right w-6rem">{{ paymentDetails.taxRate }}%</span>
          </div>

          <div
            class="flex justify-content-start align-items-center mb-2"
            *ngIf="paymentDetails.applyTax"
          >
            <span class="font-semibold">Tax fee: </span>
            <span class="text-right w-6rem">{{ paymentDetails.taxFee | currency : "USD" }}</span>
          </div>

          <div
            class="flex justify-content-start align-items-center mb-2"
            *ngIf="paymentDetails.applyCCFee"
          >
            <span class="font-semibold">Credit card fee: </span>
            <span class="text-right w-6rem">{{ paymentDetails.ccFee | currency : "USD" }}</span>
          </div>
          <div class="align-items-center border-black-alpha-30 border-top-1 flex justify-content-start mb-2 pt-3">
            <span class="font-semibold">Total: </span>
            <span class="text-right w-6rem font-semibold">{{ paymentDetails.total | currency : "USD" }}</span>
          </div>
        </div>
        <div class="flex justify-content-end mr-4 mt-2">
          <button
            pButton
            id="card-field-submit-button"
            [loading]="isSubmitting"
            label="Pay now"
            type="button"
            class="button primary"
            (click)="onSubmit()"
          ></button>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="paymentStep === paymentSteps.COMPLETED">
    <h1>Payment success</h1>
    <div class="flex flex-wrap">
      <div class="col-12">
        <div class="flex flex-column align-items-start mr-4">
          <div class="flex justify-content-start align-items-center mb-2">
            <span class="font-semibold w-8rem">Status: </span>
            <span class="text-right w-6rem">{{ orderData.status }}</span>
          </div>
          <div class="flex justify-content-start align-items-center mb-2">
            <span class="font-semibold w-8rem">Transaction ID: </span>
            <span class="text-right w-6rem">{{ orderData.transactionId }}</span>
          </div>
          <div class="flex justify-content-start align-items-center mb-2" *ngIf="orderData.vaultId">
            <span class="font-semibold w-8rem">Vault ID: </span>
            <span class="text-right w-6rem">{{ orderData.vaultId }}</span>
          </div>
          <div class="flex justify-content-start align-items-center mb-2">
            <span class="font-semibold  w-8rem">Card last digits: </span>
            <span class="text-right w-6rem">{{ orderData.cardLastDigits }}</span>
          </div>
          <div class="flex justify-content-start align-items-center mb-2">
            <span class="font-semibold w-8rem">Net Amount: </span>
            <span class="text-right w-6rem">{{ orderData.netAmount | currency : "USD" }}</span>
          </div>
          <div class="flex justify-content-start align-items-center mb-2">
            <span class="font-semibold w-8rem">Tax: </span>
            <span class="text-right w-6rem">{{ orderData.tax | currency : "USD" }}</span>
          </div>
          <div class="flex justify-content-start align-items-center mb-2">
            <span class="font-semibold w-8rem">Credit card fee: </span>
            <span class="text-right w-6rem">{{ orderData.ccFee | currency : "USD" }}</span>
          </div>
          <div class="flex justify-content-start align-items-center mb-2">
            <span class="font-semibold w-8rem">Total: </span>
            <span class="text-right w-6rem">{{ orderData.total | currency : "USD" }}</span>
          </div>
          <div class="flex justify-content-start align-items-center mb-2" *ngIf="itemType == 'TICKET'">
            <button pButton (click)="backToPayment()">Go back to payment</button>
            <button (click)="back()" pButton label="Go back to ticket" class="ml-2" rel="noopener noreferrer" *ngIf="items.length === 1"></button>
            <a [routerLink]="['/tickets']" rel="noopener noreferrer" class="p-button ml-2">Go back to tickets</a>
          </div>
          <div class="flex justify-content-start align-items-center mb-2" *ngIf="itemType == 'INVOICE'">
            <button pButton (click)="backToPayment()">Go back to payment</button>
            <button (click)="back()" pButton label="Go back to invoice" class="ml-2" rel="noopener noreferrer" *ngIf="items.length === 1"></button>
            <a [routerLink]="['/invoices']" rel="noopener noreferrer" class="p-button ml-2">Go back to invoices</a>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div *ngIf="paymentStep === paymentSteps.FAILED">
    <h1>Payment failed</h1>
    <h4 class="text-red-700">{{ lastError }}</h4>

    <div class="flex flex-wrap">
      <div class="col-12">
        <div class="flex flex-column align-items-start mr-4">
          <div class="flex justify-content-start align-items-center mb-2" *ngIf="itemType">
            <button pButton (click)="backToPayment()">Go back to payment</button>
          </div>
          <div class="flex justify-content-start align-items-center mb-2" *ngIf="itemType == 'TICKET' && items.length === 1">
            <button (click)="back()" pButton label="Go back to ticket" rel="noopener noreferrer"></button>
          </div>
          <div class="flex justify-content-start align-items-center mb-2" *ngIf="itemType == 'TICKET'">
            <a [routerLink]="['/tickets']" rel="noopener noreferrer" class="p-button">
              Go back to tickets
            </a>
          </div>
          <div class="flex justify-content-start align-items-center mb-2" *ngIf="itemType == 'INVOICE' && items.length === 1">
            <button (click)="back()" pButton label="Go back to invoice" rel="noopener noreferrer"></button>
          </div>
          <div class="flex justify-content-start align-items-center mb-2" *ngIf="itemType == 'INVOICE'">
            <a [routerLink]="['/invoices']" rel="noopener noreferrer" class="p-button">
              Go back to invoices
            </a>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
