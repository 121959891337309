<form [formGroup]="form" class="flex align-items-end justify-content-between flex-wrap gap-3" (ngSubmit)="search()" (reset)="reset()">
    <div class="flex align-items-start gap-3 flex-wrap">
      <div class="flex flex-column">
        <label> Ticket # </label>
        <input pInputText type="number" placeholder="Number of Ticket ID" formControlName="ticketId" />
      </div>

      <div class="flex flex-column" *ngIf="allowedToUseTechFilter()">
        <label>Areas</label>
        <p-multiSelect
          [options]="formattedAreas$ | async"
          formControlName="area"
          optionLabel="nameCode"
          optionValue="id"
          [filter]="true"
          showClear="true"
          placeholder="Name / Code"
          [style]="{ width: '250px', maxWidth: '100%' }"
          display="chip"
        >
        </p-multiSelect>
      </div>


      <div class="flex flex-column" *ngIf="allowedToUseTechFilter()">
        <label> Technicians </label>
        <p-multiSelect
          [options]="formattedTechnician$ | async"
          formControlName="technician"
          optionLabel="nameCode"
          optionValue="id"
          [filter]="true"
          showClear="true"
          placeholder="Name / Code"
          [style]="{ width: '250px', maxWidth: '100%' }"
      ></p-multiSelect>
      </div>

      <!-- <div class="flex flex-column">
        <label>Show result only </label>
        <p-checkbox name="hideOthers" formControlName="hideOthers" binary="true"></p-checkbox>
      </div> -->
    </div>

  <div class="flex align-items-center gap-3 flex-wrap">
    <div>
      <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
      <button pButton type="submit" label="Search" icon="pi pi-search"></button>
    </div>
  </div>
</form>
