import { Component, Input, OnInit } from '@angular/core';
import { ContactPerson } from '../../../../api/model/ContactPerson.model';
import {Partner} from '../../../../api/model/Partner.model';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';

@Component({
  selector: 'tj-partner-details',
  templateUrl: './partner-details.component.html',
  styleUrls: ['./partner-details.component.scss']
})
export class PartnerDetailsComponent implements OnInit {

  @Input() partner: Partner;

  @Input() mainContactPerson: ContactPerson;

  @Input() partnerBusinessStatus: string;

  constructor() {
  }

  ngOnInit() {
  }

  isPrivateBusiness(): boolean {
    return this.partnerBusinessStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE_BUSINESS];
  }

  isPrivate(): boolean {
    return this.partnerBusinessStatus === TicketPartnerStatus[TicketPartnerStatus.PRIVATE];
  }

}
