<form novalidate
      autocomplete="off"
      [formGroup]="filterForm"
      (reset)="onSearchReset()"
      (ngSubmit)="onFilter()">
  <div class="grid">
    <div class="col-12">
      <tj-pay-form-search-form [filter]="filter"
                               [form]="filterForm"
                               group="payFormFilter"></tj-pay-form-search-form>
    </div>
    <div class="col">
      <div class="grid align-items-center justify-content-between">
        <div class="sm:col-8">
          <div class="grid justify-content-start">
            <div class="col-fixed">
              <tj-pay-form-search-labels
                (remove)="onRemoveFilter($event)"
                [payFormSearchFilter]="filter"></tj-pay-form-search-labels>
            </div>
            <div class="col-fixed">
              <span *ngIf="page">Total: {{page.totalElements}}</span>
            </div>
          </div>
        </div>
        <div class="md:col-4 sm:col-12 text-right">
          <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
          <button pButton type="submit" label="Search" icon="pi pi-search"></button>

        </div>

      </div>
    </div>
  </div>
</form>
