import {Component, OnInit, ViewChild} from '@angular/core';
import {Page, PageQuery, SortQuery} from '../../../../api/model/shared/Page.model';
import {ActivatedRoute, Params, Router} from '@angular/router';
import {ApiService} from '../../../../api/service/api.service';
import {NotificationService} from '../../../../core/services';
import {InvoiceSearchService} from '../../service/invoice-search.service';
import {InvoiceSearchFilterModel} from '../../models/invoice-search-filter.model';
import {InvoiceList} from '../../../../api/model/InvoiceList.model';
import {DropdownModel} from '../../../../api/model/shared/dropdown.model';
import {InvoiceListComponent} from '../invoice-list/invoice-list.component';
import {download} from '../../../../api/model/shared/functions';
import {Messages} from '../../../../common/messages';
import {forkJoin} from 'rxjs';
import {environment} from '../../../../../environments/environment';

@Component({
  selector: 'tj-invoices',
  templateUrl: './invoices.component.html',
  styleUrls: ['./invoices.component.scss']
})
export class InvoicesComponent implements OnInit {

  public readonly env = environment;

  page: Page<InvoiceList>;

  invoices: InvoiceList[];

  invoiceFilter: InvoiceSearchFilterModel;

  batchActions: DropdownModel[] = [
    {label: 'Select all unprinted', value: 'SELECT_UNPRINTED'},
    {label: 'Print', value: 'PRINT'},
    {label: 'Pdf', value: 'PDF'},
    {label: 'Pay', value: 'PAY'},
    {label: 'Resend', value: 'RESEND'},
  ];

  private pageQuery: PageQuery = {
    size: this.env.rowsPerPage[0],
    number: 0
  };

  private sortQuery: SortQuery = {
    property: 'id',
    direction: 'desc'
  };

  searchQuery;

  @ViewChild('invoicesListComponent') invoicesListComponent: InvoiceListComponent;

  constructor(private route: ActivatedRoute,
              private router: Router,
              private api: ApiService,
              private invoiceSearchService: InvoiceSearchService,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
    this.invoiceSearchService.listenQueryParams();
    this.invoiceSearchService.searchData.subscribe(({filters, searchQuery}) => {
      this.invoiceFilter = filters;
      this.searchQuery = searchQuery;
    });
    this.route.queryParams.subscribe((params: Params) => {
      this.pageQuery.number = params.page || 0;
      this.pageQuery.size = +params.size || environment.rowsPerPage[0];
      this.sortQuery.property = params.property || 'id';
      this.sortQuery.direction = params.direction || 'desc';
      this.loadInvoices();
    });
  }

  setBatchAction(event: any): void {
    const selectedInvoices = this.invoicesListComponent.getSelectedInvoices();
    if (!selectedInvoices) {
      this.notificationService.warning('Please select an invoice');
      return;
    }
    switch (event.value) {
      case 'SELECT_UNPRINTED':
        this.invoicesListComponent.selectUnprinted();
        break;
      case 'PRINT':
        this.printInvoices(selectedInvoices);
        break;
      case 'PAY':
        this.pay(selectedInvoices.map(it => it.id));
        break;
      case 'PDF':
        this.downloadPdfInvoices(selectedInvoices.map(it => it.id));
        break;
      case 'RESEND':
        this.resendInvoices(selectedInvoices.map(it => it.id));
        break;
    }
  }

  sort($event) {
    this.sortQuery = {
      property: $event.field,
      direction: $event.order === -1 ? 'desc' : 'asc',
    };
    this.router.navigate([], {queryParams: this.sortQuery, queryParamsHandling: 'merge'});
  }

  onGoToPage(event) {
    this.router.navigate([], {queryParams: {page: event.page, size: event.rows}, queryParamsHandling: 'merge'});
  }

  printInvoices(selectedInvoices: InvoiceList[]): void {
    if (!selectedInvoices || selectedInvoices.length === 0) {
      this.notificationService.info('No invoice selected');
      return;
    }
    const subs = selectedInvoices
      .map(invoice => this.api.invoice.markAsPrinted(invoice.id));
    forkJoin(subs).subscribe(result => {
      const queryParams = selectedInvoices.map(inv => `id=${inv.id}`).join('&');
      const printUrl = `/invoices/print?${queryParams}`;
      this.loadInvoices();
      window.open(printUrl, '_parent');
    });
  }

  downloadPdfInvoices(selectedInvoices: number[]): void {
    if (!selectedInvoices || selectedInvoices.length === 0) {
      this.notificationService.info('No invoice selected');
      return;
    }
    this.api.invoice.download(selectedInvoices).subscribe((value: Blob) => {
      const selectedInvoiceObjects = this.invoices.filter(invoice => selectedInvoices.includes(invoice.id));

      // Check if all selected invoices have the status 'ESTIMATE'
      const allEstimates = selectedInvoiceObjects.every(invoice => invoice.status === 'ESTIMATE');

      let fileName = 'Invoice_';

      if (allEstimates) {
        fileName = 'Estimate_';
      } else if (selectedInvoices.length === 1) {
        const findInvoice = selectedInvoiceObjects[0];
        if (findInvoice && findInvoice.status === 'ESTIMATE') {
          fileName = 'Estimate_';
        }
      }

      download(value, fileName + new Date().toISOString().slice(0, -5));
      this.loadInvoices();
    }, (err) => {
      this.notificationService.error(Messages.INVOICE_CANT_PRINT, err);
    });
  }

  resendInvoices(selectedInvoices: number[]): void {
    if (selectedInvoices.length === 0) {
      this.notificationService.info('No invoice selected');
      return;
    }
    this.api.invoice.resend(selectedInvoices).subscribe(() => {
      this.notificationService.success(Messages.INVOICE_EMAIL_RESEND);
    }, (err) => {
      this.notificationService.error(Messages.INVOICE_EMAIL_CANT_RESEND, err);
    });
  }

  private loadInvoices() {
    this.api.invoice
      .findAll(this.pageQuery, this.sortQuery, this.searchQuery).subscribe(
      (invoicePage: Page<InvoiceList>) => {
        this.page = invoicePage;
        this.invoices = invoicePage.response;
      },
      error => this.notificationService.error(error)
    );
  }

  private pay(numbers: number[]) {
    const invoices = numbers.join(',');
    const toPaymentPage = () =>
      this.router.navigate(['/payment'], {
        queryParams: {itemType: 'invoice', itemId: invoices},
        queryParamsHandling: 'merge'
      });
    toPaymentPage();
  }
}

