<p-panel header="Filters" styleClass="mb-4">
  <tj-calendar-search-form [form]="form"></tj-calendar-search-form>
</p-panel>
<div class="flex flex-wrap p-1 justify-content-end">
  <div>
    <button pButton (click)="prev()" icon="pi pi-arrow-left"></button>
  </div>
  <div class="ml-2">
    <button pButton (click)="next()" icon="pi pi-arrow-right"></button>
  </div>
</div>
<full-calendar [options]='calendarOptions' class="table-row-bottom-border">
  <ng-template #eventContent let-arg>
    <div class="event-container flex flex-column">
      <div class="flex align-items-center justify-content-between m-1">
        <!-- Calendar Title -->
        <strong  tooltipPosition="top"  [pTooltip]="getTechnicianInfo(arg.event.extendedProps.technician)">{{ arg.event.title }}-{{ arg.event.extendedProps.stops.length }}</strong>
        <!-- P/D, G/B, M/T -->
        <div class="flex align-items-center">
          <div class="flex align-items-center" *ngFor="let stop of arg.event.extendedProps['stops']">
            <span class="text-blue-500" pTooltip="Pick up / Delivery" *ngIf="stop.pickUpDelivery">
              P/D<ng-container *ngIf="stop.recallGoingBack || arg.event.extendedProps.technicians.length">,</ng-container>
            </span>
            <span class="text-blue-500" pTooltip="Recall / Going Back" *ngIf="stop.recallGoingBack">G/B<ng-container *ngIf="arg.event.extendedProps.technicians.length">,</ng-container></span>
          </div>
          <span class="text-blue-500" *ngIf="arg.event.extendedProps.technicians.length" [pTooltip]="allTechnicians">M/T</span>
        </div>
     </div>
      <!-- Vacation -->
      <div  *ngIf="arg.event.extendedProps['technician'].vacations.length">
        <div *ngFor="let vacation of arg.event.extendedProps['technician'].vacations">
          <div *ngIf="isCurrentDateWithinVacation(arg.event.start, vacation.startDate, vacation.endDate)">
            <div class="flex flex-wrap text-red-500 font-bold">
              Vacation: {{ vacation.startDate | date: 'shortDate' }}-{{ vacation.endDate | date: 'shortDate' }}
            </div>
          </div>
        </div>
      </div>

      <!-- Weekly Off and Time Off (only if no vacation is shown) -->
      <div *ngIf="!hasCurrentDateVacation(arg.event.start, arg.event.extendedProps['technician'].vacations)">
        <div *ngIf="arg.event.extendedProps['technician'].weeklyOffs.length">
          <div *ngFor="let weeklyOff of arg.event.extendedProps['technician'].weeklyOffs">
            <div *ngIf="isMatchingDate(arg.event.start, weeklyOff.weekDay)">
              <!-- Check if the whole day is off -->
              <div class="text-red-500 font-bold" *ngIf="weeklyOff.timeOff?.wholeDay">
                Day off
              </div>
              <!-- Check if there are specific time ranges for the day, and no day off or vacation -->
              <div *ngIf="!weeklyOff.timeOff?.wholeDay && weeklyOff.timeOff?.timeRange.length">
                <div class="flex align-items-center flex-wrap" *ngIf="weeklyOff.timeOff?.timeRange.length">
                  <span class="text-red-500 font-bold">T/off:</span>
                  <div class="text-red-500 font-bold" *ngFor="let timeRange of weeklyOff.timeOff.timeRange; let last = last">
                    {{ formatTime(timeRange.startTime) }}-{{ formatTime(timeRange.endTime) }}<ng-container *ngIf="!last">,</ng-container>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <!-- Chips -->
      <div class="flex flex-wrap p-1 truncate-width">
        <p-chip *ngFor="let ticketsPerArea of arg.event.extendedProps.ticketsPerArea | keyvalue" class="mr-1 mb-1 tags-wrapper">
          <span class="text-xs" [pTooltip]="ticketsPerArea.value[0]" *ngIf="ticketsPerArea.value[0].length > 10">
            {{ ticketsPerArea.value[0].substring(0, 10) }}...:<b>{{ ticketsPerArea.value[1] }}</b>
          </span>
          <span class="text-xs" *ngIf="ticketsPerArea.value[0].length <= 10">
            {{ ticketsPerArea.value[0] }}: <b>{{ ticketsPerArea.value[1] }}</b>
          </span>
        </p-chip>
      </div>
    </div>
  </ng-template>
</full-calendar>

<p-dialog [(visible)]="showStopsDialog" [style]="{width: '1000px'}" header="Stops" [modal]="true"
          (onHide)="hideDialog()"
          styleClass="p-fluid">

  <ng-template pTemplate="header">
    <h3>{{selectedTechnician.name}} - {{selectedDate | date: configService.dateFormat}}</h3>
  </ng-template>

  <ng-template pTemplate="content">
    <tj-technician-stop-form-scheduler
        [selectedStops]="selectedStops"
        [selectedTechnician]="selectedTechnician"
        [selectedDate]="selectedDate"
    ></tj-technician-stop-form-scheduler>
  </ng-template>

</p-dialog>
