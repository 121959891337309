import {Component, Input, OnInit} from '@angular/core';
import {TicketTechnician} from '../../../../api/model/TicketTechnicians.model';
import {coerceBooleanInput} from '../../../shared/utils/coerce-boolean-input';
import {TjKeycloakService} from '../../../../core/services';

@Component({
  selector: 'tj-ticket-technicians',
  templateUrl: './ticket-technicians.component.html',
  styleUrls: ['./ticket-technicians.component.scss']
})
export class TicketTechniciansComponent implements OnInit {

  @Input() technicians: TicketTechnician[];

  public shortMode: boolean;

  @Input()
  get short() {
    return this.shortMode;
  }

  set short(value: any) {
    this.shortMode = coerceBooleanInput(value);
  }

  constructor(private keycloakServices: TjKeycloakService) {
  }

  ngOnInit() {
  }

  getNameAbbr(name: string) {
    return name.split(' ')
      .map(parts => parts.slice(0, 1).toUpperCase())
      .join('');
  }

  public canViewTechnicianPersonCode(): boolean {
    return this.keycloakServices.hasRole('TICKET_VIEW_TECHNICIAN_PERSONNEL_CODE');
  }

}
