import heic2any from 'heic2any';
import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class ImageFormatConverterComponent {
  private readonly IMAGE_QUALITY = 0.7;

  convertImages(files: File[]): Promise<File[]> {
    if (!files || files.length === 0) {
      return Promise.resolve([]);
    }
    return this.convertAllToJpg(files);
  }

  private convertAllToJpg(files: File[]): Promise<File[]> {
    const conversionPromises: Promise<File>[] = files.map((file) => this.convertToJpg(file));
    return Promise.all(conversionPromises);
  }

  private convertToJpg(file: File): Promise<File> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

      reader.onload = (event: any) => {
        const arrayBuffer = event.target.result;
        const fileName = file.name.toLowerCase();

        if (this.isHeicFile(fileName)) {
          this.convertHeicToJpg(arrayBuffer)
            .then((result: any) => {
              const jpgFile = this.createJpgFile(file, result);
              resolve(jpgFile);
            })
            .catch(reject);
        } else {
          resolve(file);
        }
      };

      reader.onerror = reject;

      reader.readAsArrayBuffer(file);
    });
  }

  private isHeicFile(fileName: string): boolean {
    return fileName.endsWith('.heic');
  }

  private convertHeicToJpg(arrayBuffer: ArrayBuffer): Promise<Blob | Blob[]> {
    return heic2any({
      blob: new Blob([arrayBuffer], {type: 'image/heic'}),
      toType: 'image/jpeg',
      quality: this.IMAGE_QUALITY,
    });
  }

  private createJpgFile(originalFile: File, result: ArrayBuffer): File {
    const fileNameWithoutExtension = originalFile.name.replace(/\.[^/.]+$/, '');
    return new File([result], `${fileNameWithoutExtension}.jpg`, {type: 'image/jpeg'});
  }
}
