import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {UntypedFormGroup} from '@angular/forms';
import {FormHelpers} from '../../../shared/utils/form-helpers';
import {Customer} from '../../../../api/model/Customer.model';
import {TicketEditContextService} from '../../services/ticket-edit-context.service';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {NotificationService} from '../../../../core/services';
import {Messages} from '../../../../common/messages';
import {Router} from '@angular/router';

@Component({
  selector: 'tj-ticket-edit-customer-modal',
  templateUrl: './ticket-edit-customer-modal.component.html',
  styleUrls: ['./ticket-edit-customer-modal.component.scss']
})
export class TicketEditCustomerModalComponent implements OnInit {

  private _visible;

  @Input('visible') set visible(value) {
    this._visible = value;
  }

  get visible() {
    return this._visible;
  }

  @Output() hide = new EventEmitter();

  subscriptions = [];

  form: UntypedFormGroup;

  originalCustomer: Customer;

  customer: Customer;

  originalMainContactPerson: ContactPerson;

  mainContactPerson: ContactPerson;

  constructor(private context: TicketEditContextService,
              private router: Router,
              private notificationService: NotificationService) {
  }

  ngOnInit() {
  }

  onShow(): void {
    this.form = new UntypedFormGroup({});
    this.subscriptions.push(this.context.customer.subscribe(customer => {
      if (customer) {
        this.originalCustomer = customer;
        this.customer = Customer.buildCopy(customer);
      }
    }));

    this.subscriptions.push(this.context.mainCustomerContactPerson.subscribe(contactPerson => {
      this.originalMainContactPerson = contactPerson;
      this.mainContactPerson = contactPerson;
    }));
  }

  onHide(): void {
    this.subscriptions.forEach(s => s.unsubscribe());
    this.subscriptions.length = 0;
    this.form = undefined;
    this.hide.emit();
  }

  onSave(): void {
    const formValue = this.form.value;
    if (this.form.valid) {
      const customer: Customer = formValue.customer;
      customer.id = this.customer.id;
      this.context.saveCustomer(customer)
        .subscribe(() => {
          this.onHide();
          this.notificationService.success(Messages.CUSTOMER_SAVED);
          this.refreshPage();
        });
    } else {
      FormHelpers.validateAllFormFields(formValue.customer);
    }
  }

  private refreshPage() {
    const currentUrl = this.router.url;
    this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
      this.router.navigate([currentUrl]);
    });
  }
}
