<div class="grid">
  <div class="sm:col-12" *ngIf="partner">
    <ng-container *ngIf="isPrivateBusiness(); else privateOnly">
      <span class="private">PRIVATE</span> - 3rd Party - None Account
      <hr>
    </ng-container>
    <ng-template #privateOnly>
      <ng-container *ngIf="isPrivate()">
        <span class="private">PRIVATE</span>
        <hr>
      </ng-container>
    </ng-template>
  </div>
  <div class="sm:col-12" *ngIf="partner">
    <span class="name">{{partner.name}}</span>
    <span class="partner-type"> / {{partner.partnerType}}</span><br/>
    <ng-container *ngIf="partner.notes">
      <hr class="m-0">
      <span class="name py-2">
        <strong>Notes: </strong>
        <div class="ml-3">
          {{partner.notes}}
        </div>
      </span>
      <hr class="m-0">
    </ng-container>
    <ng-container *ngIf="partner.address">
      <span class="font-bold mt-1" style="font-size: 16px;">Address:</span>
      <div class="ml-3" style="font-size: 14px !important;">
        <tj-address-link [address]="partner.address"></tj-address-link>
      </div>
      {{partner.address.additionalAddress}}
    </ng-container>
  </div>
  <div class="sm:col-12" *ngIf="!partner">
    No account defined
  </div>
</div>
