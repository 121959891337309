import {Component, Input, OnInit} from '@angular/core';
import {Ticket} from '../../../../api/model/Ticket.model';
import {ConfigService} from '../../../../core/services';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {Customer} from '../../../../api/model/Customer.model';
import {TicketEditContextService} from '../../services/ticket-edit-context.service';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {Partner} from '../../../../api/model/Partner.model';
import {ContactType} from '../../../shared/enums/contact.type';
import {LayoutService} from '../../../../layout/service/app.layout.service';
import {SignatureApi} from '../../../../api/endpoints/signature.api';


@Component({
  selector: 'tj-ticket-print',
  templateUrl: './ticket-print.component.html',
  styleUrls: ['./ticket-print.component.scss'],
  providers: [TicketEditContextService]
})
export class TicketPrintComponent implements OnInit {

  @Input() ticketId: number;
  @Input() index = 0;

  @Input() configs: { [key: string]: string };

  @Input() showTotalAmount = true;

  @Input() pagebreak = true;

  signature: string;

  ticket: Ticket;

  customer: Customer;

  partner: Partner;

  mainCustomerContactPerson: ContactPerson;

  mainPartnerContactPerson: ContactPerson;

  ticketPartnerStatus: TicketPartnerStatus;

  contactType = ContactType;

  constructor(private context: TicketEditContextService,
              public configService: ConfigService,
              public layoutService: LayoutService,
              private gallerySignature: SignatureApi) {
  }

  ngOnInit() {
    this.context.ticket.subscribe(ticket => this.ticket = ticket);
    this.context.partner.subscribe(partner => this.partner = partner);
    this.context.customer.subscribe(customer => this.customer = customer);
    this.context.ticketPartnerStatus.subscribe(ticketPartnerStatus => this.ticketPartnerStatus = ticketPartnerStatus);
    this.context.mainCustomerContactPerson.subscribe(mainCustomerContactPerson => this.mainCustomerContactPerson = mainCustomerContactPerson);
    this.context.mainPartnerContactPerson.subscribe(mainPartnerContactPerson => this.mainPartnerContactPerson = mainPartnerContactPerson);
    this.context.loadTicket(this.ticketId);
    this.gallerySignature.getSignature(this.ticketId).subscribe(
      (signatureResponse) => {
        if (signatureResponse) {
          this.signature = signatureResponse.url;
        }
      }
    );
  }

  getPartnerContactPeople() {
    return this.partner?.printAllContactPersons ? this.partner.contactPersons : this.ticket.mainPartnerContactPerson ? [this.ticket.mainPartnerContactPerson] : [];
  }

  getAreas(): string {
    const areas = this.customer.address.areas;
    return !areas ? '-' : areas.map(it => it.code).join(', ');
  }
}
