import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {AttachmentListResponseModel} from '../../models/Gallery.model';
import {DomSanitizer} from '@angular/platform-browser';
import {NotificationService, TjKeycloakService} from '../../../../core/services';
import {GalleryService} from '../../services';
import {ApiService} from '../../../../api/service/api.service';
import {
  AttachmentModel,
  AudioRequestModel,
  DocumentRequestModel,
  ImageRequestModel,
  VideoRequestModel
} from '../../models/ImageRequest.model';
import {GalleryManager} from '../../utils/gallery-manager.model';
import {Messages} from '../../../../common/messages';
import {Ticket} from '../../../../api/model/Ticket.model';
import {ImageEditComponent} from '../../../uploader/components';
import {ConfirmationService} from 'primeng/api';
import {TicketEditContextService} from '../../../ticket/services/ticket-edit-context.service';
import {GalleryItemsCounts} from '../../../../api/model/GalleryItemsCounts.model';
import {ProgressBarService} from '../../../../core/services/progressbar.service';

@Component({
  selector: 'tj-gallery-viewer',
  templateUrl: './gallery-viewer.component.html',
  styleUrls: ['./gallery-viewer.component.scss']
})
export class GalleryViewerComponent implements OnInit {

  attachments: any[] = [];
  currentPage = 1;
  itemsPerPage = 10;
  totalRecords = 0;


  @Input() galleryId = 0;

  @Input() ticket: Ticket;

  @ViewChild('editComponent')
  editComponent: ImageEditComponent;

  selectedTabIndex = 0;
  galleryItemsCounts: GalleryItemsCounts;
  newAttachments: string[] = [];

  galleryManager: GalleryManager;
  openImageEditModal = false;

  constructor(private sanitizer: DomSanitizer,
              private apiService: ApiService,
              private notificationService: NotificationService,
              private confirmationService: ConfirmationService,
              private ticketEditContextService: TicketEditContextService,
              private galleryService: GalleryService,
              private keycloakService: TjKeycloakService,
              private progressBarService: ProgressBarService) {
    this.galleryManager = new GalleryManager(this.galleryService, progressBarService);
  }

  ngOnInit() {
    this.totalRecords = this.ticket?.galleryItemsCounts?.imageCount;
    if (this.galleryId) {
      this.galleryItemsCounts = this.ticket.galleryItemsCounts;
      this.onTabChange(this.selectedTabIndex);
    }
    this.ticketEditContextService.ticketGalleryEmmit.subscribe((response) => {
      if (Array.isArray(response)) {
        this.newAttachments = response.map(gallery => gallery.fileType);
      }
      this.onTabChange(this.selectedTabIndex);
    });
  }

  canEditAttachments(): boolean {
    return this.keycloakService.hasRole('TICKET_EDIT_ATTACHMENTS');
  }

  remove(imageId: number, index: number): void {
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete the image?',
      header: 'Pay form deletion',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDeletion',
      accept: () => {
        this.apiService.gallery.remove(imageId, this.ticket.id).subscribe((file) => {
          this.notificationService.success(Messages.FILE_REMOVED);
        });
        this.attachments.splice(index, 1);
      }
    });
  }

  select(model: AttachmentModel, add, index: number): void {
    model.index = index;
    if (add) {
      this.galleryManager.add(model);
    } else {
      this.galleryManager.delete(model);
    }
  }

  removeSelected(): void {
    this.newAttachments = [];
    this.confirmationService.confirm({
      message: 'Are you sure you want to delete selected files?',
      header: 'Attachment deletion',
      icon: 'pi pi-exclamation-triangle',
      key: 'confirmDeletion',
      accept: () => {
        const ids: number[] = this.galleryManager.getIds();
        this.attachments = this.attachments.filter(value => !this.galleryManager.hasAttachment(value));
        this.galleryManager.clear();
        this.apiService.gallery.removeAll(this.galleryId, ids, this.ticket.id).subscribe((file) => {
          this.correctedGalleryItemsCount(ids.length);
          this.notificationService.success(Messages.FILE_REMOVED);
        });
      }
    });
  }

  correctedGalleryItemsCount(itemsCount: number) {
    switch (this.selectedTabIndex) {
      case 0:
        this.galleryItemsCounts.imageCount -= itemsCount;
        break;
      case 1:
        this.galleryItemsCounts.videoCount -= itemsCount;
        break;
      case 2:
        this.galleryItemsCounts.documentCount -= itemsCount;
        break;
      case 3:
        this.galleryItemsCounts.audioCount -= itemsCount;
        break;
    }
  }

  download(fileType: AllowedFileTypes): void {
    this.galleryManager.downloadAll(fileType);
  }

  downloadImageZipFile() {
    this.galleryManager.downloadImageZipFile(this.ticket.id);
    this.unselectAll();
  }


  selectAll(): void {
    let index = 1;
    this.attachments.forEach(att => {
      att.selected = true;
      att.index = index;
      this.galleryManager.add(att);
      index++;
    });
  }

  editImage(file: ImageRequestModel, index): void {
    this.editComponent.edit(file, index);
  }

  onImageEditDone(imageId: number): void {
    this.attachments.forEach((image, index) => {
      if (image.id === imageId) {
        // updating fragment (#) updating query breaks the AWS signed url
        this.attachments[index].url = this.galleryService.getImagePath((image as ImageRequestModel).cropPath) + '#t=' + new Date().getTime();
      }
    });
  }

  getImageThumbnailUrl(image: ImageRequestModel): string {
    return this.galleryService.getImagePath(image.cropPath, 400, 0);
  }

  onTabChange(index: any) {
    this.galleryManager.clear();
    this.selectedTabIndex = index;
    switch (this.selectedTabIndex) {
      case 0:
        this.loadImages();
        break;
      case 1:
        this.loadVideos();
        break;
      case 2:
        this.loadDocuments();
        break;
      case 3:
        this.loadAudios();
        break;
    }
  }

  loadImages() {
    this.apiService.gallery.getImages(this.galleryId, this.ticket.id)
      .subscribe((responseModel: AttachmentListResponseModel<ImageRequestModel>) => {
        this.attachments = [];
        this.attachments = responseModel.attachments.map(
          img => {
            img.cropPath = img.cropPath + '?v=' + new Date().getTime();
            return img;
          }
        );
        this.galleryItemsCounts.imageCount = this.attachments.length;
      });
  }

  loadVideos() {
    this.apiService.gallery.getVideos(this.galleryId, this.ticket.id)
      .subscribe((responseModel: AttachmentListResponseModel<VideoRequestModel>) => {
        this.attachments = [];
        this.attachments = responseModel.attachments;
        this.galleryItemsCounts.videoCount = this.attachments.length;
      });
  }

  loadAudios() {
    this.apiService.gallery.getAudios(this.galleryId, this.ticket.id)
      .subscribe((responseModel: AttachmentListResponseModel<AudioRequestModel>) => {
        this.attachments = [];
        this.attachments = responseModel.attachments;
        this.galleryItemsCounts.audioCount = this.attachments.length;
      });
  }

  loadDocuments() {
    this.apiService.gallery.getDocuments(this.galleryId, this.ticket.id)
      .subscribe((responseModel: AttachmentListResponseModel<DocumentRequestModel>) => {
        this.attachments = responseModel.attachments;
        this.galleryItemsCounts.documentCount = this.attachments.length;
      });
  }

  paginatedAttachments(): any[] {
    const start = (this.currentPage - 1) * this.itemsPerPage;
    const end = start + this.itemsPerPage;
    return this.attachments.slice(start, end);
  }

  onPageChange(event: any): void {
    this.currentPage = event.page + 1;
    this.itemsPerPage = event.rows;
  }

  unselectAll() {
    this.attachments.forEach(att => {
      att.selected = false;
    });
    this.galleryManager.clear();
  }
}
