import {Component, Input, OnInit, QueryList, ViewChild, ViewChildren} from '@angular/core';

import {ConfigService, NotificationService, TjKeycloakService} from '../../../../core/services';
import {ApiService} from '../../../../api/service/api.service';
import {AddressDetails} from '../../../../api/model/Address.model';
import {AddressSearchComponent} from '../../../shared/components';
import {RouteMetricsService} from '../../route.metrics';
import {RouteDetails} from '../../models/route-data';
import {MapService} from '../../../shared/services/map.service';
import {FormControl, UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {RouteService} from '../../route.service';
import {TechnicianTicketDetails} from '../../../../api/model/technician/technician.ticket.details';
import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {TechnicianTicketsResponse} from '../../../../api/model/technician/technician.tickets.response';
import {RouteListModel} from '../../../../api/model/route/route.line.model';
import * as dayjs from 'dayjs';
import {DialogService, DynamicDialogRef} from 'primeng/dynamicdialog';
import {filter} from 'rxjs/operators';
import {TicketEditServiceTimeModalComponent} from '../../../ticket/components';
import {Router} from '@angular/router';

export class AddressedMarker {
  id: number;
  address: AddressDetails;
  serviceTimeStart: Date;
  serviceTimeEnd: Date;
  timeNeeded = 0;
  ticketId: number;
  customerFormControl: FormControl;
  ticketFormControl: FormControl;
  marker: google.maps.Marker;

  constructor(id, address, marker) {
    this.id = id;
    this.address = address;
    this.marker = marker;
    this.customerFormControl = new FormControl();
    this.ticketFormControl = new FormControl();
  }

}

@Component({
  selector: 'tj-routes',
  templateUrl: './routes.component.html',
  styleUrls: ['./routes.component.scss']
})
export class RoutesComponent implements OnInit {

  private readonly ORIGIN_ICON: string = 'assets/images/flag_start.png';

  private readonly DESTINATION_ICON: string = 'assets/images/flag_end.png';

  private readonly UNSET_LOCATION: google.maps.LatLng = new google.maps.LatLng(0, 0);

  private readonly ADDRESS_COUNT: number = 2;

  private selectedTechnician: TechnicianMinimalListItem;

  private directionsService = new google.maps.DirectionsService();

  private directionsRenderer = new google.maps.DirectionsRenderer();

  @ViewChildren(AddressSearchComponent) addresses: QueryList<AddressSearchComponent>;

  @ViewChild('gmap') public map: google.maps.Map;

  public mapDefaultConfig = {
    zoom: 15,
    center: new google.maps.LatLng(40.18609961626319, 44.51486725725317),
  };

  public routeDetails: RouteDetails;

  @Input() public startAddress: AddressDetails;

  @Input() public endAddress: AddressDetails;

  @Input() public route: RouteListModel;

  protected currentDateRouteId: number;

  public hideEndAddress = true;

  public waypoints: any[] = [];

  legs: google.maps.DirectionsLeg[];

  selectedLeg: google.maps.DirectionsLeg;

  visibleLegSidebar = false;

  private firstServiceTimeStart: Date;

  formGroup: UntypedFormGroup;

  startPoint: AddressedMarker = new AddressedMarker(1, new AddressDetails(), new google.maps.Marker({
      position: this.UNSET_LOCATION,
      zIndex: 2,
      icon: {
        url: this.ORIGIN_ICON,
        scaledSize: new google.maps.Size(16, 16)
      },
    })
  );

  endpoint: AddressedMarker = new AddressedMarker(2, new AddressDetails(), new google.maps.Marker({
      position: this.UNSET_LOCATION,
      zIndex: 1,
      icon: {
        url: this.DESTINATION_ICON,
      },
    })
  );

  mediaDialogRef: DynamicDialogRef;

  public directionComponents: AddressedMarker[] = [];

  constructor(private apiService: ApiService, private notificationService: NotificationService,
              public config: ConfigService,
              private routeMetricsService: RouteMetricsService,
              private routeService: RouteService,
              private router: Router,
              private tjKeycloakService: TjKeycloakService,
              private fb: UntypedFormBuilder,
              public dialogService: DialogService,
              private mapService: MapService) {
  }

  ngOnInit() {
    this.formGroup = this.fb.group({
      date: new UntypedFormControl(),
      email: new UntypedFormControl(null, [Validators.required, Validators.email]),
      technicianId: new UntypedFormControl(),
    });
    this.formGroup.get('date').valueChanges.subscribe(value => this.onDateEdit(value));

    if (this.tjKeycloakService.hasRole('ROUTES_PLANING_VIEW_ASSIGNED')) {
      this.getTechnicianTodayRoute();
    }
    if (this.route) {
      this.openForRoute();
      setTimeout(() => {
        this.showRoute();
      }, 1000);
      return;
    }

    for (let i = 1; i <= this.ADDRESS_COUNT; i++) {
      this.directionComponents.push(
        new AddressedMarker(i, new AddressDetails(), new google.maps.Marker({
            position: this.UNSET_LOCATION,
            title: null,
            label: {text: '' + i}
          })
        )
      );
    }
  }

  public selectTechnician(technician: TechnicianMinimalListItem): void {
    this.selectedTechnician = technician;
    this.loadTechnicianAddress(technician.id);
    const date = this.formGroup.get('date').getRawValue();
    if (!date) {
      return;
    }
    this.apiService.technician.getTechnicianTickets(technician.id, date)
      .subscribe((response: TechnicianTicketsResponse) => {
        this.formGroup.get('email').setValue(response.email);
        const routingDetails: TechnicianTicketDetails[] = response.tickets;
        if (routingDetails?.length > 0) {
          this.firstServiceTimeStart = routingDetails[0].serviceTimeStart;
        }
        if (routingDetails.length > this.directionComponents.length) {
          const diff: number = routingDetails.length - this.directionComponents.length;
          for (let i = 0; i < diff; i++) {
            this.onAddAddress();
          }
        }
        for (let i = 0; i < routingDetails.length; i++) {
          const now = new Date();
          now.setHours(routingDetails[i].timeNeeded);
          now.setMinutes(0);
          this.directionComponents[i].ticketId = routingDetails[i].id;
          this.directionComponents[i].serviceTimeStart = routingDetails[i].serviceTimeStart;
          this.directionComponents[i].serviceTimeEnd = routingDetails[i].serviceTimeEnd;
          this.directionComponents[i].timeNeeded = routingDetails[i].timeNeeded;
          this.directionComponents[i].customerFormControl.setValue(routingDetails[i].customer);
          this.directionComponents[i].ticketFormControl.setValue(routingDetails[i].id);
          this.directionComponents[i].address = {getFormattedAddress: routingDetails[i].address.getFormattedAddress, ...routingDetails[i].address};
        }
      });
  }

  public showRoute(): void {
    if (!this.startAddress || !this.startAddress.addressFormatted ||
      !this.endAddress || !this.endAddress.addressFormatted) {
      this.notificationService.error('Start address or end address are not specified');
      return;
    }
    Promise.all(this.requestRoute())
      .then(value => this.drawMap());
  }

  private requestRoute(): Promise<void> [] {
    this.addresses.notifyOnChanges();
    const directions = this.directionComponents
      .map(address => address.address);
    if (directions.length === 0 || this.startAddress === null) {
      this.notificationService.error('Please check address and selected technician');
      return;
    }
    this.resetMap();
    return this.directionComponents
      .filter(it => it.address.addressFormatted !== undefined)
      .map(addressMarker =>
        this.mapService.findAddressLatLng(addressMarker.address.addressFormatted)
          .then(it => {
            addressMarker.address.lat = it.lat;
            addressMarker.address.lng = it.lng;
            addressMarker.marker.setOptions({position: {...addressMarker.address}});
          }));
  }

  public onCreateAndSend() {
    Promise.all(this.requestRoute())
      .then(value => this.drawMap()
        .then(_ => this.create(true)));
  }

  public onSave() {
    Promise.all(this.requestRoute())
      .then(value => this.drawMap()
        .then(_ => this.create(false)));
  }

  public onSend() {
    const email = this.formGroup.get('email').getRawValue();
    Promise.all(this.requestRoute())
      .then(value => this.drawMap()
        .then(_ => {
          this.sendRoute(this.route.id, email);
        }));
  }

  private create(notify: boolean): void {
    const date = this.formGroup.get('date').getRawValue() as Date;
    if (!this.firstServiceTimeStart) {
      this.firstServiceTimeStart = dayjs.utc(date).hour(8).minute(0).second(0).toDate();
    }
    this.routeService.createRoute(
      date,
      this.firstServiceTimeStart,
      this.formGroup.get('email').getRawValue(),
      this.directionComponents,
      this.startAddress,
      this.endAddress,
      this.legs,
      this.selectedTechnician,
      notify
    );
  }

  private resetMap() {
    this.startPoint.marker.setMap(null);
    this.endpoint.marker.setMap(null);
    this.waypoints.forEach(it => it.setMap(null));
    // this.clearArray(this.waypoints);
    if (this.directionsRenderer) {
      this.directionsRenderer.setMap(null);
    }
  }

  private drawMap(): Promise<any> {
    this.directionComponents.forEach(it => this.waypoints.push(it.marker));

    this.startPoint.marker.setOptions({position: {...this.startAddress}});

    if (this.hideEndAddress) {
      this.endpoint.marker.setOptions({position: {...this.startAddress}});
    } else {
      this.endpoint.marker.setOptions({position: {...this.endAddress}});
    }

    this.waypoints.push(this.startPoint.marker, this.endpoint.marker);

    const locations: google.maps.DirectionsWaypoint[] = this.directionComponents
      .filter(it => it.address.lat !== undefined && it.address.lng !== undefined)
      .map(it => {
        return {
          location: {
            lat: it.address.lat,
            lng: it.address.lng
          },
          stopover: true,
        } as google.maps.DirectionsWaypoint;
      });

    return this.directionsService.route(
      {
        origin: this.startAddress.addressFormatted,
        destination: this.hideEndAddress ? this.startAddress.addressFormatted : this.endAddress.addressFormatted,
        waypoints: locations,
        travelMode: google.maps.TravelMode.DRIVING,
      }, (response: google.maps.DirectionsResult, status: any) => {
        if (status === 'OK') {
          this.legs = response.routes[0].legs;
          this.routeDetails = this.routeMetricsService.computeTotalDistanceAndDuration(this.legs, this.directionComponents);
          this.directionsRenderer.setOptions({
            suppressMarkers: true,
            directions: response,
          });
          this.directionsRenderer.setMap(this.map['map']);
          this.directionsRenderer.setPanel(document.getElementById('panel') as HTMLElement);
        }
      }
    );
  }

  startAddressChange(address: AddressDetails) {
    this.startAddress = address;
    if (this.hideEndAddress) {
      this.endAddress = address;
    }
  }

  endAddressChange(address: AddressDetails) {
    this.endAddress = address;
  }

  public onAddressChange(addressModel: AddressDetails, index: number): void {
    this.mapDefaultConfig.zoom = 18;
    this.directionComponents[index].marker
      .setOptions({position: new google.maps.LatLng({lat: addressModel.lat, lng: addressModel.lng})});
    this.directionComponents[index].address = addressModel;
  }

  public onReorder(): void {
    this.directionComponents.forEach((a, index) => {
      a.id = index + 1;
      a.marker.setOptions({label: {text: `${a.id}`}});
    });
    this.showRoute();
  }

  setEndAddress(showEndAddress) {
    this.hideEndAddress = showEndAddress;
    if (this.hideEndAddress) {
      this.endAddress = this.startAddress;
    }
  }

  getDuration(index: number): number {
    const duration = this.legs[index].duration.value;
    let seconds = 0;
    if (index > 0 && this.directionComponents[index - 1]) {
      seconds = this.directionComponents[index - 1].timeNeeded * 3600;
    }
    return duration + seconds;
  }

  getDistance(index: number): number {
    return this.legs[index].distance.value;
  }

  getTotalDuration(): number {
    return this.routeMetricsService.computeTotalDistanceAndDuration(this.legs, this.directionComponents)
      .duration;
  }

  getTotalDistance(): number {
    return this.routeMetricsService.computeTotalDistanceAndDuration(this.legs, this.directionComponents)
      .distance;
  }

  private getTechnicianTodayRoute() {
    const date = dayjs().utc().hour(13).minute(0).second(0).toISOString();
    this.apiService.routeApi.getRouteByDate(date)
      .subscribe(value => {
        this.currentDateRouteId = value.id;
      });
  }

  private loadTechnicianAddress(technicianId: number) {
    this.apiService.technician.getAddress(technicianId)
      .subscribe((addressDetails: AddressDetails) => {
        this.startAddress = addressDetails;
        this.mapService.findAddressLatLng(addressDetails.addressFormatted)
          .then(it => {
            this.startAddress.lng = it.lng;
            this.startAddress.lat = it.lat;
          });
        this.endAddress = this.startAddress;
      });
  }

  onAddAddress() {
    const newIndex = this.directionComponents.length + 1;
    const newAddressedMarker = new AddressedMarker(newIndex, new AddressDetails(), new google.maps.Marker({
        position: this.UNSET_LOCATION,
        title: null,
        label: {text: '' + newIndex}
      })
    );
    this.directionComponents = [...this.directionComponents, newAddressedMarker];
  }


  onDateEdit(date: Date) {
    if (this.selectedTechnician) {
      this.selectTechnician(this.selectedTechnician);
    }
  }

  private openForRoute() {
    this.formGroup.get('technicianId').setValue(this.route.technicianId);
    this.formGroup.get('date').setValue(this.route.date);
    this.formGroup.get('email').setValue(this.route.email);
    const stops = this.route.stops;
    for (let i = 0; i < stops.length; i++) {
      const stop = stops[i];
      if (i === 0) {
        this.startAddress = stop.address;
        continue;
      }
      if (i === stops.length - 1) {
        this.endAddress = stop.address;
        continue;
      }
      const addressDetails = stop.address;
      const addressedMarker = new AddressedMarker(i, addressDetails, new google.maps.Marker({
          position: new google.maps.LatLng(addressDetails.lat, addressDetails.lng),
          label: {text: '' + (i)}
        })
      );
      addressedMarker.customerFormControl.setValue(stop.customer);
      addressedMarker.serviceTimeStart  = stop.ticketServiceTimeStart;
      addressedMarker.serviceTimeEnd  = stop.ticketServiceTimeEnd;
      addressedMarker.timeNeeded  = stop.ticketTimeNeeded;
      addressedMarker.ticketFormControl.setValue(stop.ticketId);
      addressedMarker.ticketId = stop.ticketId;
      this.directionComponents.push(addressedMarker);
    }
  }

  private sendRoute(routeId: number, email: string) {
    this.apiService.routeApi.sendRoute(routeId, email)
      .subscribe(value => {
        this.notificationService.success('Route has been sent');
      });
  }

  openServiceDateEditDialog(addressMarker: AddressedMarker) {
    this.mediaDialogRef = this.dialogService.open(TicketEditServiceTimeModalComponent, {
      header: `Edit service date / time`,
      contentStyle: {
        maxWidth: '100%',
        overflow: 'hidden',
      },
      data: {
        serviceTimeStart: addressMarker.serviceTimeStart,
        serviceTimeEnd: addressMarker.serviceTimeEnd,
        timeNeeded: addressMarker.timeNeeded,
      },
    });
    return this.mediaDialogRef.onClose.pipe(
      filter(data => data !== undefined));
  }

  editServiceDates(addressMarker: AddressedMarker) {
    this.openServiceDateEditDialog(addressMarker)
      .subscribe(data => {
        const ticketId = addressMarker.ticketId;
        const serviceTimeStart = data.serviceTimeStart;
        const serviceTimeEnd = data.serviceTimeEnd;
        const timeNeeded = data.timeNeeded;
        this.apiService.ticket.updateDates(ticketId, serviceTimeStart, serviceTimeEnd, timeNeeded)
          .subscribe(value => {
            addressMarker.timeNeeded = timeNeeded;
            addressMarker.serviceTimeStart = serviceTimeStart;
            addressMarker.serviceTimeEnd = serviceTimeEnd;
            this.notificationService.success('Ticket successfully updated');
          });
      });
  }

  removeStop(index: number) {
    this.directionComponents.splice(index, 1);
  }

  showLeg(legIndex: number) {
    this.selectedLeg = this.legs[legIndex];
    this.visibleLegSidebar = true;
  }

  getAddress(i: number): string {
    return this.directionComponents[i].address.addressFormatted;
  }

  onCopySuccess(event: boolean) {
    if (event) {
      this.notificationService.info('Address is copied.');
    } else {
      this.notificationService.error('Failed to copy the address.');
    }
  }

  openMap(i: number) {
    const url = 'https://maps.google.com/maps?q=' + this.directionComponents[i].address.addressFormatted;
    window.open(url, '_blank');
  }
}
