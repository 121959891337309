<p-dialog (onHide)="onHide()" (onShow)="onShow()" [(visible)]="visible"
          [autoZIndex]="false"
          [contentStyle]="{'max-height' : '50%', 'min-height': '500px', 'min-width': '300px'}"
          [maximizable]="true" [minY]="70" [responsive]="true"
          blockScroll="true"
          header="Edit Account / Requested by" styleClass="dialog" maskStyleClass="mask-style-dialog">

  <form (submit)="onSave()" *ngIf="form" [formGroup]="form" id="partnerForm">
    <div class="grid">

      <div class="col-12 flex justify-content-end">
        <div class="p-sm-fixed">
          <button (click)="onChangePartner()" *ngIf="ticketPartnerStatus === ticketPartnerStatusEnum.REGISTERED"
                  class="p-button-secondary p-button-raised" label="Change account"
                  pButton
                  type="button"></button>
        </div>
        <div class="p-sm-fixed ml-2">
          <button (click)="onDetachPartner()" *ngIf="ticketPartnerStatus !== ticketPartnerStatusEnum.PRIVATE" class="p-button-secondary p-button-raised" label="Make ticket private"
                  pButton
                  type="button"></button>
        </div>
      </div>


      <!-- NO PARTNER - SHOW SELECTOR -->
      <ng-container *ngIf="!partner">
        <div class="sm:col-12 partner-type-selector">
          <tj-partner-type-selector (partnerChange)="onSelectPartner($event)"
                                    (usePrivatePartner)="onUsePrivatePartner()"
                                    [partner]="partner"
                                    [ticketPartnerStatus]="ticketPartnerStatus"
          ></tj-partner-type-selector>
        </div>
      </ng-container>

      <!-- PRIVATE PARTNER - SHOW FORM -->
      <ng-container *ngIf="ticketPartnerStatus === ticketPartnerStatusEnum.PRIVATE_BUSINESS">
        <div class="sm:col-12">
          <tj-subtitle>New account</tj-subtitle>
          <tj-private-partner-form [form]="form" [partner]="partner" group="partner"></tj-private-partner-form>
        </div>
      </ng-container>

      <!-- REGISTERED PARTNER - SHOW DETAILS -->
      <ng-container *ngIf="ticketPartnerStatus === ticketPartnerStatusEnum.REGISTERED">
        <div class="col">
          <tj-partner-details [mainContactPerson]="mainContactPerson" [partner]="partner"></tj-partner-details>
        </div>
        <div class="sm:col-12">
          <tj-subtitle>Contact person</tj-subtitle>
          <div class="partner-contact-person-selector-container">
            <tj-partner-contact-person-selector
              (contactPersonChange)="onContactPersonChange($event)"
              [form]="form"
              [mainContactPerson]="mainContactPerson"
              [partner]="partner"></tj-partner-contact-person-selector>
            <ng-container *ngIf="mainContactPerson && mainContactPerson.id">
              <tj-contact-person-details [contactPerson]="mainContactPerson"
                                         title="Selected contact person"></tj-contact-person-details>
            </ng-container>
          </div>
        </div>
      </ng-container>
    </div>
  </form>
  <p-footer>
    <p-button (click)="visible=false" class="p-button-secondary" icon="pi pi-times" label="Cancel"></p-button>
    <button [disabled]="!partner" form="partnerForm" icon="pi pi-check" label="Save" pButton type="submit"></button>
  </p-footer>
</p-dialog>
