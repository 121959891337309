<div [formGroup]="currentFormGroup">
  <tj-field [control]="fcMessage">
    <tj-custom-input>
      <div class="p-inputgroup">
        <input pInputText formControlName="message" type="text">
        <button pButton pRipple icon="pi pi-send" styleClass="p-button-warn" type="submit"></button>
      </div>
    </tj-custom-input>
  </tj-field>
</div>
