<p-dialog (onHide)="onHide()" (onShow)="onShow()" [(visible)]="visible"
          [autoZIndex]="false"
          [contentStyle]="{'overflow-y':'scroll'}"
          [height]="700" [maximizable]="true" [minY]="70" [responsive]="true" blockScroll="true"
          header="Edit customer" maskStyleClass="mask-style-dialog" styleClass="dialog">
  <form #customerForm *ngIf="form" [formGroup]="form" id="customerForm">
    <tj-customer-edit-form [customer]="customer"
                           [form]="form"
                           [mainContactPerson]="mainContactPerson"
                           group="customer"></tj-customer-edit-form>
  </form>
  <ng-template pTemplate="footer">
    <p-button (click)="onHide()" class="p-button-secondary" icon="pi pi-times" label="Cancel"></p-button>
    <p-button (click)="onSave()" [disabled]="!customer"  icon="pi pi-check"
              label="Save"></p-button>
  </ng-template>
</p-dialog>
