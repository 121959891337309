<div>
  <span *ngFor="let filter of technicianFilter | keys"
    (click)="onRemoveFilter(filter.key)"
    class="search-label">
    <ng-container [ngSwitch]="filter.key">
      <span *ngSwitchCase="'private'">PRIVATE</span>
      <span *ngSwitchCase="'deleted'">{{filterNames[filter.key]}}</span>
      <span *ngSwitchCase="'createDate'">{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}</span>
      <span *ngSwitchCase="'serviceDate'">{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}</span>
      <span *ngSwitchCase="'contactDate'">{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}</span>
      <span *ngSwitchCase="'partnerId'">{{filterNames[filter.key]}}: {{partnersMap[filter.value].name}}</span>
      <span *ngSwitchCase="'technicianId'">{{filterNames[filter.key]}}: {{techniciansMap[filter.value].name}}</span>
      <span *ngSwitchCase="'tags'">{{filterNames[filter.key]}}:
        <span *ngFor="let tag of filter.value">{{tag.label}}</span>
      </span>
      <span *ngSwitchDefault>{{filterNames[filter.key]}}: {{filter.value}} </span>
    </ng-container>
    <span><i class="fi-x"></i></span>
  </span>
</div>
