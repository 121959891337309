import {Component, EventEmitter, Input, OnChanges, Output, SimpleChanges} from '@angular/core';
import {BaseFormGroup} from '../../../shared/utils/base-form-group';
import {TicketInvoiceWithTech} from '../../../invoice/service/invoice.service';
import {UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {TicketStatus} from '../../../../api/status/ticketStatus';
import {ConfirmationService} from 'primeng/api';
import {TicketPartnerStatus} from '../../../../api/status/ticket-partner.status';
import {PaymentType} from '../../../../api/status/payment.type';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-quick-invoice-form',
  templateUrl: './quick-invoice-form.component.html',
  styleUrls: ['./quick-invoice-form.component.scss']
})
export class QuickInvoiceFormComponent extends BaseFormGroup implements OnChanges {

  @Input() ticketInvoice: TicketInvoiceWithTech;
  @Input() form: UntypedFormGroup;
  @Input() group: string;
  protected readonly TicketStatus = TicketStatus;

  @Output() invoiceChange = new EventEmitter();

  TicketPartnerStatus = TicketPartnerStatus;

  PaymentType = PaymentType;
  invoiceNetTerm: number;

  private registeredAccountDefaultNetTerm = 0;
  private privateAccountDefaultNetTerm = 0;

  constructor(private confirmationService: ConfirmationService,
              private configService: ConfigService) {
    super();
    this.registeredAccountDefaultNetTerm = configService.getRegisteredAccountDefaultNetTerm();
    this.privateAccountDefaultNetTerm = configService.getPrivateAccountDefaultNetTerm();
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.ticketInvoice?.firstChange || this.ticketInvoice !== changes.ticketInvoice?.previousValue) {
      const ticketInvoice = this.ticketInvoice;
      this.invoiceNetTerm = 0;
      if (this.ticketInvoice.ticket.invoiceId) {
        this.invoiceNetTerm = this.ticketInvoice.netTerm;
      } else {
        if (ticketInvoice.ticket.ticketPartnerStatus === TicketPartnerStatus.REGISTERED) {
          this.invoiceNetTerm = ticketInvoice.ticket.partner.netTerm;
        } else {
          this.invoiceNetTerm = this.privateAccountDefaultNetTerm;
        }
      }
      const formControls = {
        invoiceAmount: new UntypedFormControl(ticketInvoice.amount || 0),
        invoiceExpenses: new UntypedFormControl(ticketInvoice.expenses || 0),
        invoiceTax: new UntypedFormControl(ticketInvoice.tax || 0),
        invoiceNetTerm: new UntypedFormControl(this.invoiceNetTerm || 0),
        isInvoicePaid: new UntypedFormControl(false)
      };

      this.buildForm(this.form, formControls, this.group);
      this.defineGetters(formControls);

      this.currentFormGroup.valueChanges.subscribe((invoiceChanges) => {
        this.invoiceChange.emit(invoiceChanges);
      });
    }
  }

  confirmPaid($event: any) {
    if ($event.checked) {
      this.confirmationService.confirm({
        message: 'Are you sure this invoice already paid and should be converted to paid status?',
        header: 'Change invoice status',
        icon: 'pi pi-exclamation-triangle',
        key: 'confirmChangeInvoiceStatusToPaid',
        accept: () => {
          console.log('form confirm value', this.currentFormGroup.get('isInvoicePaid').value);
        },
        reject: () => {
          this.currentFormGroup.get('isInvoicePaid').setValue(false);
          console.log('form confirm value', this.currentFormGroup.get('isInvoicePaid').value);
        },
      });
    }
  }
}
