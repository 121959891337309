import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import {AuthGuardService} from '../../core/services';
import {RoutesPageComponent} from './pages';
import {
  RouteDirectionsComponent,
  RouteListFilterComponent,
  RoutesComponent,
  RouteSearchChipsComponent,
  RoutesListComponent,
  RouteViewComponent,
} from './components';
import {RouteMetricsService} from './route.metrics';
import {MapService} from '../shared/services/map.service';
import {RouteService} from './route.service';
import {RouteListFilterFormComponent} from './forms';
import {TicketModule} from '../ticket/ticket.module';
import {ClipboardModule} from '@angular/cdk/clipboard';


@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  declarations: [
    RoutesPageComponent,
    RoutesComponent,
    RouteListFilterFormComponent,
    RouteDirectionsComponent,
    RouteSearchChipsComponent,
    RouteListFilterComponent,
    RouteViewComponent,
    RoutesListComponent,
  ],
  imports: [
    SharedModule,
    RouterModule.forRoot([
      {
        path: 'routes',
        component: RoutesPageComponent,
        canActivate: [AuthGuardService],
        data: {
          breadcrumb: 'Previous routes',
          roles: []
        }
      },
      {
        path: 'routes',
        canActivate: [AuthGuardService],
        children: [
          {
            path: 'new',
            pathMatch: 'full',
            component: RoutesComponent,
            data: {
              breadcrumb: 'New route',
              roles: []
            }
          },
          {
            path: ':id',
            component: RouteViewComponent,
            canActivate: [AuthGuardService],
          }
        ]
      },
    ]),
    TicketModule,
    ClipboardModule,
  ],
  providers: [
    MapService,
    RouteService,
    RouteMetricsService
  ]
})
export class RoutesModule {
}
