export class User {
  email: string;
  name: string;
  nickname: string;
  picture: string;
  attributes: [];
  authorities: string[];
  id: number | string;
  username: string;
}

export class UserBaseModel {
  id: number;
  name: string;
}
