import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup, Validators} from '@angular/forms';
import {Technician} from '../../../../../api/model/Technician.model';
import {BaseFormGroup} from '../../../../shared/utils/base-form-group';
import {ConfigService, NotificationService} from '../../../../../core/services';
import {Subject} from 'rxjs';
import {PayType} from '../../../../shared/enums/pay.type';
import {Vacation} from '../../../../../api/model/Vacation.model';
import * as dayjs from 'dayjs';


@Component({
  selector: 'tj-technician-edit-form',
  templateUrl: './technician-edit-form.component.html',
  styleUrls: ['./technician-edit-form.component.scss']
})
export class TechnicianEditFormComponent extends BaseFormGroup implements OnInit, OnDestroy {
  private readonly CATEGORY_MAX_LENGTH: number = 160;

  private _technician: Technician;

  private idSubject: Subject<number> = new Subject<number>();

  readonly PayType = PayType;

  disabledVacationDates = [];

  @Input() set technician(technician: Technician) {
    this._technician = technician;
    this.idSubject.next(technician.id);
    if (this.form) {
      this.form.reset(technician);
      this.findDisabledDates(technician?.vacations);
      this.onSortVacations({'field': 'startDate', 'order': 1});
    }
  }

  get technician() {
    return this._technician;
  }

  @Input() form: UntypedFormGroup;

  @Input() group: string;

  constructor(public configService: ConfigService,
              public fb: UntypedFormBuilder,
              public notificationService: NotificationService) {
    super();
  }

  ngOnInit(): void {
    this.initializeForm();
    this.idSubject.subscribe(id => {
      this.updateForm(id);
    });
    this.updateForm(this.technician.id);
    this.getControl('areas').patchValue(this.technician.areas);
    this.findDisabledDates(this.technician?.vacations);

  }

  getControl(fcName: string): UntypedFormControl {
    return this.form.get(fcName) as UntypedFormControl;
  }

  private updateForm(id: number): void {
    this.toggleFormsState(id);
    this.togglePasswordValidator(id);
    this.togglePaymentType();
  }

  private initializeForm(): void {
    this.form.addControl('dateRange', this.fb.control(null));
    this.form.addControl('name', this.fb.control(this.technician.name));
    this.form.addControl('username', this.fb.control(this.technician.username, [Validators.required]));
    this.form.addControl('personnelCode', this.fb.control(this.technician.personnelCode));
    this.form.addControl('password', this.fb.control(this.technician.password, [Validators.required]));
    this.form.addControl('firstName', this.fb.control(this.technician.firstName, [Validators.required]));
    this.form.addControl('lastName', this.fb.control(this.technician.lastName, [Validators.required]));
    this.form.addControl('companyName', this.fb.control(this.technician.companyName));
    this.form.addControl('speciality', this.fb.control(this.technician.speciality, Validators.maxLength(this.CATEGORY_MAX_LENGTH)));
    this.form.addControl('phoneNumber', this.fb.control(this.technician.phoneNumber, [Validators.required]));
    this.form.addControl('email', this.fb.control(this.technician.email, [Validators.required]));
    this.form.addControl('notes', this.fb.control(this.technician.notes));
    this.form.addControl('payType', this.fb.control(this.technician.payType, [Validators.required]));
    this.form.addControl('payFormDateRangeType', this.fb.control(this.technician.payFormDateRangeType));
    this.form.addControl('areas', this.fb.control(this.technician.areas));
    this.form.addControl('showOnCalendar', this.fb.control(this.technician.showOnCalendar));
    this.form.addControl('maxStops', this.fb.control(this.technician.maxStops));
    this.form.addControl('maxWorkHours', this.fb.control(this.technician.maxWorkHours));
    this.form.addControl('vacations', this.fb.control(this.technician.vacations || []));

    this.changePayType(this.technician.payType);
  }

  onPayTypeChange(event): void {
    this.changePayType(event.value);
  }

  private togglePaymentType() {
    this.form.get('payType').valueChanges.subscribe(this.changePayType);
  }

  private changePayType(payType: string): void {
    if (payType === PayType.COMMISSION_BASED) {
      this.form.addControl('percent', this.fb.control(this.technician.percent, [Validators.required]));
      this.form.removeControl('unit');
    }
    if (payType === PayType.HOUR_BASED) {
      this.form.addControl('unit', this.fb.control(this.technician.unit, [Validators.required]));
      this.form.removeControl('percent');
    }
  }

  private togglePasswordValidator(id): void {
    const fcPassword = this.getControl('password');
    if (id) {
      fcPassword.clearValidators();
    } else {
      fcPassword.setValidators([Validators.required]);
    }
  }

  private toggleFormsState(id): void {
    const usernameFc = this.getControl('username');
    if (id) {
      usernameFc.disable();
    } else {
      usernameFc.enable();
    }
  }

  ngOnDestroy(): void {
    if (this.idSubject) {
      this.idSubject.complete();
    }
  }

  deleteVacation(index: number) {
    const vacations = this.getControl('vacations').value as Vacation[];
    vacations.splice(index, 1); // Remove the vacation at the specified index
    this.getControl('vacations').setValue(vacations); // Update the control with the new list
    this.findDisabledDates(vacations);
  }

  addVocationRange() {
    const dateRange = this.getControl('dateRange').value;

    if (!dateRange || dateRange[0] === null || dateRange[1] === null) {
      this.notificationService.error('Start date and end date are required.');
      this.getControl('dateRange').reset();
      return;
    }

    // Parse the dates
    const startDateParsed = dayjs(dateRange[0]);
    const endDateParsed = dayjs(dateRange[1]);

    // Check if the parsed dates are valid
    if (!startDateParsed.isValid() || !endDateParsed.isValid()) {
      this.notificationService.error('Invalid date format.');
      this.getControl('dateRange').reset();
      return;
    }

    // Format the dates as required
    const startDate = startDateParsed.hour(13).minute(0).second(0).millisecond(0).toJSON();
    const endDate = endDateParsed.hour(13).minute(0).second(0).millisecond(0).toJSON();

    const newVacation = new Vacation(startDate, endDate);
    const vacations = this.getControl('vacations').value as Vacation[];

    for (const vacation of vacations) {
      if (this.isDateRangeOverlap(newVacation, vacation)) {
        this.notificationService.error('The new vacation overlaps with an existing vacation.');
        this.getControl('dateRange').reset();
        return;
      }
    }

    vacations.push(newVacation);

    this.getControl('vacations').setValue(vacations);
    this.getControl('dateRange').reset();
    this.onSortVacations({'field': 'startDate', 'order': 1});
    this.findDisabledDates(vacations);

  }

  isDateRangeOverlap(vacation1: Vacation, vacation2: Vacation): boolean {
    return (dayjs(vacation1.startDate).isBefore(dayjs(vacation2.endDate)) || dayjs(vacation1.startDate).isSame(dayjs(vacation2.endDate))) &&
      (dayjs(vacation2.startDate).isBefore(dayjs(vacation1.endDate)) || dayjs(vacation2.startDate).isSame(dayjs(vacation1.endDate)));
  }

  onSortVacations(event: any) {
    const field = event.field; // The field to sort by 'startDate' or 'endDate'
    const order = event.order; // The order to sort, 1 for asc, -1 for desc

    const vacations = this.getControl('vacations')?.value as Vacation[];
    const sortedVacations = vacations?.sort((a, b) => {
      const aDate = dayjs(a[field]);
      const bDate = dayjs(b[field]);

      if (aDate.isBefore(bDate)) {
        return order === 1 ? -1 : 1;
      } else if (aDate.isAfter(bDate)) {
        return order === 1 ? 1 : -1;
      } else {
        return 0;
      }
    });

    this.getControl('vacations').setValue(sortedVacations);
  }

  findDisabledDates(vacations: Vacation[]): void {
    if (vacations && vacations.length > 0) {
      this.disabledVacationDates = [];

      vacations.forEach(vacation => {
        const startDate = dayjs(vacation.startDate);
        const endDate = dayjs(vacation.endDate);

        // Iterate through each date in the vacation range
        for (let date = startDate; !date.isAfter(endDate); date = date.add(1, 'day')) {
          this.disabledVacationDates.push(date.toDate());
        }
      });
    } else {
      this.disabledVacationDates = [];
    }
  }
}
