import {Injectable} from '@angular/core';
import {RouteItemModel, RouteRequestModel} from '../../api/model/route/route.request.model';
import {AddressedMarker} from './components/routes/routes.component';
import {TechnicianMinimalListItem} from '../../api/model/TechnicianMinimalListItem';
import {NotificationService} from '../../core/services';
import {AddressDetails} from '../../api/model/Address.model';
import {ApiService} from '../../api/service/api.service';
import {Router} from '@angular/router';

@Injectable()
export class RouteService {

    constructor(private api: ApiService, private notificationService: NotificationService,
                private router: Router) {
    }

      createRoute(date: Date,
                startTime: Date,
                email: string,
                addressedMarkers: AddressedMarker[],
                startAddress: AddressDetails,
                endAddress: AddressDetails,
                legs: google.maps.DirectionsLeg[],
                technician: TechnicianMinimalListItem,
                notify): void {

        const routes: RouteItemModel[] = addressedMarkers
            .filter(it => it.address.addressFormatted !== undefined)
            .map(it => this.mapMarkerToRouteItemModel(it.customerFormControl.value, it.address, it.timeNeeded, it.ticketFormControl?.getRawValue()));
        routes.push(this.mapMarkerToRouteItemModel(technician.name, endAddress, 0, null));
        (legs || []).forEach((leg: google.maps.DirectionsLeg, index: number) => {
            routes[index].distance = leg.distance.value;
            routes[index].duration = leg.duration.value;
        });

        const start = this.mapMarkerToRouteItemModel(technician.name, startAddress, 0, null);
        routes.unshift(start);

        const requestModel: RouteRequestModel = new RouteRequestModel();
        requestModel.startTime = startTime;
        requestModel.email = email;
        requestModel.date = date;
        requestModel.technicianId = technician.id;
        requestModel.routes = routes;
        this.api.routeApi.create(requestModel, notify)
            .subscribe((response) => {
              const message = notify ? 'The route has been saved and sent' : 'The route has been saved';
              this.router.navigate(['/routes', response.id]);
              this.notificationService.success(message);
            }, error => {
                this.notificationService.error('Something went wrong while sending an email', error);
            });
    }

    private mapMarkerToRouteItemModel(name: string, address: AddressDetails, time: number, ticketId: number) {
        const item: RouteItemModel = new RouteItemModel();
        item.name = name;
        item.address = address;
        item.addressUrl = 'https://maps.google.com/maps?q=' + address.addressFormatted;
        item.serviceTime = time;
        item.ticketId = ticketId;
        return item;
    }


}
