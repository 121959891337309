import { Injectable } from '@angular/core';
import { User } from '../../api/model/User.model';
import { Observable } from 'rxjs';
import { KeycloakService } from 'keycloak-angular';
import { KeycloakProfile } from 'keycloak-js';
import {UserRoles} from '../../main/shared/models/user-roles';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root',
})
export class TjKeycloakService {
  user = new User();

  constructor(
    private keycloakService: KeycloakService
  ) {}

  initializeKeycloak(keycloakConfig) {
    return this.keycloakService.init({
      config: keycloakConfig,
      initOptions: {
        onLoad: 'login-required',
        checkLoginIframe: false,
      },
      enableBearerInterceptor: true,
      bearerExcludedUrls: ['/assets'],
    });
  }

  getUser(): Observable<User> {
    return Observable.create((observer) => {
      this.keycloakService
        .loadUserProfile()
        .then((fetchedUser: KeycloakProfile) => {
          const idTokenParsed =
            this.keycloakService.getKeycloakInstance().idTokenParsed;
          this.user.id = idTokenParsed.sub;
          this.user.email = fetchedUser.email;
          this.user.attributes = fetchedUser['attributes'];
          this.user.name = idTokenParsed['name'];
          this.user.username = fetchedUser.username;
        });

      observer.next(this.user);
      observer.complete();
    });
  }

  logout() {
    this.keycloakService.getKeycloakInstance().logout();
  }

  checkIfTokenRefreshRequired() {
    return this.keycloakService.isLoggedIn()
      .then((isLoggedIn) => {
        if (isLoggedIn) {
          // Refresh token if it is valid for less than 300 seconds
          return this.keycloakService.updateToken(300);
        }
      });
  }

  hasRole(role: string | UserRoles): boolean {
    const userRoles = this.keycloakService.getUserRoles(true);
    if (userRoles && userRoles.length === 0) {
      return false;
    }
    return userRoles.indexOf(role.toString()) >= 0;
  }

  hasAnyRole(roles: string[]): boolean {
    const userRoles = this.keycloakService.getUserRoles(true);
    if (userRoles && userRoles.length === 0) {
      return false;
    }
    return roles.some(it => userRoles.indexOf(it) >= 0);
  }

}
