<div class="flex flex-wrap">
  <div *ngFor="let filter of searchFilter | keys" (click)="onRemoveFilter(filter.key)" class="mr-2 mb-2">
    <ng-container [ngSwitch]="filter.key">
      <p-chip [removable]="true" (onRemove)="onRemoveFilter(filter.key)" *ngSwitchCase="'private'" label="PRIVATE"></p-chip>
      <p-chip [removable]="true" (onRemove)="onRemoveFilter(filter.key)" *ngSwitchCase="'createDate'" label="{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}}"></p-chip>
      <p-chip [removable]="true" (onRemove)="onRemoveFilter(filter.key)" *ngSwitchCase="'serviceDate'" label="{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}"></p-chip>
      <p-chip [removable]="true" (onRemove)="onRemoveFilter(filter.key)" *ngSwitchCase="'contactDate'" label="{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}"></p-chip>
      <p-chip [removable]="true" (onRemove)="onRemoveFilter(filter.key)" *ngSwitchCase="'partnerId'" label="{{filterNames[filter.key]}}: {{partnersMap[filter.value].name}}"></p-chip>
      <p-chip [removable]="true" (onRemove)="onRemoveFilter(filter.key)" *ngSwitchCase="'issueDate'"
              label="{{filterNames[filter.key]}}: {{(filter.value.split('|')[0] | date:environment.dateFormat) + ' - ' + (filter.value.split('|')[1] | date:environment.dateFormat)}}">
      </p-chip>
      <ng-container *ngSwitchCase="'tags'">
        {{filterNames[filter.key]}}:
        <p-chip *ngFor="let tag of tags" [removable]="true" (onRemove)="onRemoveFilter(filter.key)" label="{{tag.label}}"></p-chip>
      </ng-container>
      <span *ngSwitchDefault>{{filterNames[filter.key]}}: {{filter.value}}</span>
    </ng-container>
  </div>
</div>
