<p-panel header="Filters" toggleable="true">
  <form (ngSubmit)="onFilter()"
        (reset)="onSearchReset()"
        [formGroup]="filterForm"
        autocomplete="off"
        novalidate>
    <div class="grid">
      <div class="col-12">
        <tj-invoice-search-form [filter]="filter" [form]="filterForm" group="invoiceFilter"></tj-invoice-search-form>
      </div>
    </div>
    <div class="grid justify-content-between">
      <div class="sm:col-8">
        <div class="grid justify-content-start">
          <div class="col-fixed">
            <tj-invoice-search-labels
              (remove)="onRemoveFilter($event)"
              [searchFilter]="filter"></tj-invoice-search-labels>
          </div>
          <div class="col-fixed">
            <span *ngIf="page">Total: {{page.totalElements}}</span>
          </div>
        </div>
      </div>
      <div class="md:col-4 sm:col-12 text-right">
        <button class="p-button-secondary mr-2" label="Reset" pButton type="reset"></button>
        <button icon="pi pi-search" label="Search" pButton type="submit"></button>
      </div>
    </div>
  </form>
</p-panel>
