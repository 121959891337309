<div *ngIf="standalonePage">
  <router-outlet></router-outlet>
</div>
<div *ngIf="!standalonePage" class="layout-wrapper" [ngClass]="containerClass" [class]="sidebarClass">
  <tj-notifications></tj-notifications>
  <p-progressBar mode="indeterminate" *ngIf="showProgressBar"
                 [style]="{'height': '6px', 'z-index': '999999','position': 'fixed', 'top': '0', 'left': '0', 'width': '100%'}"></p-progressBar>
  <div class="layout-content-wrapper">
    <tj-topbar></tj-topbar>
    <div class="layout-content">
      <router-outlet></router-outlet>
    </div>
    <tj-footer></tj-footer>
  </div>
  <tj-config></tj-config>
  <tj-search></tj-search>
  <div class="layout-mask"></div>
</div>
