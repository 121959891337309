
import {map} from 'rxjs/operators';
import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';

import {UntypedFormGroup} from '@angular/forms';
import {Comment, CommentListItem} from '../../../../api/model/TicketComments.model';
import {User} from '../../../../api/model/User.model';
import {ApiService} from '../../../../api/service/api.service';
import {ConfigService, TjKeycloakService, UserService} from '../../../../core/services';
import {FormHelpers} from '../../../shared/utils/form-helpers';

@Component({
  selector: 'tj-ticket-comments',
  templateUrl: './ticket-comments.component.html',
  styleUrls: ['./ticket-comments.component.scss']
})
export class TicketCommentsComponent implements OnInit, OnChanges {
  // region input/outputs
  @Input() chatId: number;
  // endregion

  // region fields
  commentForm: UntypedFormGroup;
  comment: Comment;
  comments: CommentListItem[];
  user: User;
  expanded: boolean;

  // endregion

  constructor(
    private api: ApiService,
    private userService: UserService,
    private tjKeycloakService: TjKeycloakService,
    public config: ConfigService
  ) {}

  ngOnInit() {
    this.tjKeycloakService.getUser().subscribe(user => this.user = user);
    this.initializeComment();
    this.expanded = false;
    this.commentForm = new UntypedFormGroup({});
  }

  ngOnChanges(changes: SimpleChanges) {
    if (changes.chatId || this.chatId !== changes.chatId.previousValue) {
      this.getChatComments();
    }
  }

  onCommentSave() {
    const formValue = this.commentForm.value;
    if (this.commentForm.valid) {
      const updateComment = { ...this.comment, ...formValue.comment };
      this.saveComment(updateComment).subscribe();
    }
    FormHelpers.validateAllFormFields(this.commentForm);
  }

  onExpand() {
    this.expanded = !this.expanded;
  }

  // region private methods

  initializeComment() {
    const comment = {} as Comment;
    comment.userId = this.user.id;
    comment.chatId = this.chatId;
    comment.message = '';
    this.comment = comment;
  }

  private getChatComments() {
    return this.api.comment
      .getChatComments(this.chatId).pipe(
      map((comments: CommentListItem[]) => {
        this.comments = comments;
      }))
      .subscribe();
  }

  private saveComment(comment) {
    return this.api.comment
      .save(comment).pipe(
      map((savedComment: CommentListItem) => {
        this.comments.push(savedComment);
        this.initializeComment();
        this.commentForm = new UntypedFormGroup({});
        return comment;
      }));
  }

  // endregion
}
