  <p-autoComplete
    dataKey="id"
    field="label"
    [(ngModel)]="value"
    (onSelect)="onTagAdd($event)"
    (onUnselect)="onTagRemove($event)"
    [suggestions]="filteredTags"
    (completeMethod)="onFilterSuggestions($event)"
    [completeOnFocus]="true"
    [unique]="true"
    scrollHeight="100"
    [forceSelection]="true"
    [multiple]="true"
    [disabled]="disabled">
    <ng-template let-searchItem pTemplate="selectedItem">
      <div [ngStyle]="{ 'background-color': searchItem.color }" class="ui-helper-clearfix w-fit status-pill">
        <span>{{searchItem.label}}</span>
      </div>
    </ng-template>
  </p-autoComplete>
