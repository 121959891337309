<div class="grid" [formGroup]="form">
  <div class="col-12" formGroupName="info">
    <div class="grid">
        <div class="col-3">Tech received / transfer from last week</div>
        <div class="col-4">
            <tj-field>
                <tj-money-input formControlName="transfer" (onEnter)="onEnter()"></tj-money-input>
            </tj-field>
        </div>
        <div class="col-5">
            <tj-field>
                <tj-datepicker [onlyFuture]="false" formControlName="transferDate"
                               placeholder="Transfer date"></tj-datepicker>
            </tj-field>
        </div>
    </div>
    <div class="grid">
      <div class="col-3">Added</div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="bonus" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <input pInputText formControlName="bonusDescription" (keyup.enter)="onEnter()">
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col-3">Added</div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="bonus2" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <input pInputText formControlName="bonusDescription2" (keyup.enter)="onEnter()">
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col-3">Deducted</div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="deduct" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <input pInputText formControlName="deductDescription" (keyup.enter)="onEnter()">
        </tj-field>
      </div>
    </div>
    <div class="grid">
      <div class="col-3">Deducted</div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="deduct2" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <input pInputText formControlName="deductDescription2" (keyup.enter)="onEnter()">
        </tj-field>
      </div>
    </div>
      <div class="grid">
          <div class="col-12 no-print">
              <hr>
          </div>
      </div>
      <div class="grid">
      <div class="col-3">Tech paid amount</div>
      <div class="col-4">
        <tj-field>
          <tj-money-input formControlName="paidAmount" (onEnter)="onEnter()"></tj-money-input>
        </tj-field>
      </div>
      <div class="col-5">
        <tj-field>
          <tj-datepicker [onlyFuture]="false" formControlName="paidDate" placeholder="Paid date"></tj-datepicker>
        </tj-field>
      </div>
    </div>
  </div>
</div>
