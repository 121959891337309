import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {PartnerMinimalListItem} from '../../../../api/model/PartnerLine.model';
import {Component, EventEmitter, Input, OnChanges, OnInit, Output} from '@angular/core';
import {ConfigService} from '../../../../core/services';
import {TechnicianSearchFilterModel} from '../../models/technician-search-filter.model';

@Component({
  selector: 'tj-technician-search-labels',
  templateUrl: './technician-search-labels.component.html',
  styleUrls: ['./technician-search-labels.component.scss']
})
export class TechnicianSearchLabelsComponent implements OnChanges, OnInit {
  @Input() technicianFilter: TechnicianSearchFilterModel;
  @Output() remove = new EventEmitter();

  partnersMap: { [id: number]: PartnerMinimalListItem } = {};
  techniciansMap: { [id: number]: TechnicianMinimalListItem } = {};

  filterNames = {
    name: 'Name',
    email: 'Email',
    phone: 'Phone',
    deleted: 'Deleted '
  };

  constructor(public config: ConfigService) {
  }

  ngOnInit() {
    // this.config.partners$.subscribe(value => {
    //   this.partnersMap = value.reduce((acc, partner) => {
    //     acc[partner.id] = partner;
    //     return acc;
    //   }, this.partnersMap);
    // });
    // this.config.technicians$.subscribe(value => {
    //   this.techniciansMap = value.reduce((acc, technician) => {
    //     acc[technician.id] = technician;
    //     return acc;
    //   }, this.techniciansMap);
    // });
  }

  ngOnChanges() {
  }

  onRemoveFilter(filterName) {
    const copyOfFilter = {...this.technicianFilter};
    if (copyOfFilter[filterName]) {
      copyOfFilter[filterName] = null;
    }
    this.remove.emit(copyOfFilter);
  }
}
