import {UntypedFormBuilder, UntypedFormControl, UntypedFormGroup} from '@angular/forms';
import {Component, EventEmitter, HostBinding, Input, OnInit, Output} from '@angular/core';
import {AddressDetails} from '../../../../api/model/Address.model';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-address-create-form',
  templateUrl: './address-create-form.component.html',
  styleUrls: ['./address-form.component.scss']
})
export class AddressCreateFormComponent implements OnInit {

  private _address: AddressDetails;

  @Input() set address(address: AddressDetails) {
    this._address = address;
    if (this.form && this.form.get(this.group)) {
      this.form.get(this.group).reset(address);
    }
  }

  get address() {
    return this._address;
  }

  @Output('zipCode') addressZipCode: EventEmitter<string> = new EventEmitter<string>();

  @Input() form: UntypedFormGroup;

  @Input() group: string;

  @HostBinding('class') classes = 'address-persons-formGroup';

  constructor(private fb: UntypedFormBuilder,
              private configService: ConfigService) {
  }

  ngOnInit() {
    this.initializeForm();
  }

  getControl(fcName: string): UntypedFormControl {
    return this.form.get(this.group).get(fcName) as UntypedFormControl;
  }

  addressChanged(address: AddressDetails) {
    if (address.bld) {
      this.form.get(this.group).get('street').patchValue(address.bld + ' ' + address.street);
    } else {
      this.form.get(this.group).get('street').patchValue(address.street);
    }
    this.form.get(this.group).get('apt').patchValue(address.apt);
    this.form.get(this.group).get('city').patchValue(address.city);
    this.form.get(this.group).get('state').patchValue(address.state);
    this.form.get(this.group).get('postalCode').patchValue(address.postalCode);
  }

  private initializeForm() {
    const address = this.address || ({} as AddressDetails);
    const formGroup = this.fb.group({
      country: this.fb.control('USA' || ''),
      city: this.fb.control(address.city || ''),
      state: this.fb.control(address.state || ''),
      street: this.fb.control(address.street || ''),
      apt: this.fb.control(address.apt || ''),
      postalCode: this.fb.control(address.postalCode || ''),
      addressFormatted: this.fb.control(address.addressFormatted || ''),
      additionalAddress: this.fb.control(address.additionalAddress)
    });
    this.form.addControl(this.group, formGroup);
    if (address.id) {
      formGroup['id'] = this.fb.control(address.id);
    }
    if (this.configService.isEnabledTerritory()) {
      this.isSupportedZipCode(formGroup);
    }
  }

  private isSupportedZipCode(formGroup: UntypedFormGroup): void {
    formGroup.get('postalCode').valueChanges.pipe(
      debounceTime(200),
      distinctUntilChanged())
      .subscribe(data => {
          this.addressZipCode.emit(data);
      });
  }
}
