import {NgModule, NO_ERRORS_SCHEMA} from '@angular/core';
import {SharedModule} from '../shared/shared.module';
import {RouterModule} from '@angular/router';
import { PayFormsComponent } from './pages/payforms/payforms.component';
import { PayFormComponent } from './pages/payform/payform.component';
import {AuthGuardService} from '../../core/services';
import {
  PayFormEditComponent,
  PayFormEditFormComponent,
  PayFormInfoComponent,
  PayFormSearchComponent,
  PayFormSearchFormComponent,
  PayFormSearchLabelsComponent
} from './components';
import {PayFormSearchService} from './services/pay-form-search.service';

@NgModule({
  schemas: [NO_ERRORS_SCHEMA],
  imports: [
    SharedModule,
    RouterModule.forRoot([
      {
        path: 'payforms',
        component: PayFormsComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: [],
          breadcrumb: 'Pay forms'
        }
      },
      {
        path: 'payforms/:id',
        component: PayFormComponent,
        canActivate: [AuthGuardService],
        data: {
          roles: ['PAYFORM_VIEW'],
          breadcrumb: 'Pay forms'
        }
      }
    ])
  ],
  declarations: [
    PayFormsComponent,
    PayFormComponent,
    PayFormEditComponent,
    PayFormSearchComponent,
    PayFormSearchFormComponent,
    PayFormEditFormComponent,
    PayFormInfoComponent,
    PayFormSearchLabelsComponent
  ],
  providers: [
    PayFormSearchService
  ]
})
export class PayFormModule {
}
