<div [formGroup]="form" class="grid align-items-center">
  <div [formGroupName]="group" class="col-12">
    <div class="grid justify-content-start">
      <div class="lg:col-2 md:col-6 sm:col-12 col-12">
        <tj-field [control]="getControl('technicianId')">
          <tj-technician-combobox formControlName="technicianId" placeholder="Technician"></tj-technician-combobox>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-6 sm:col-12 col-12">
        <tj-field [control]="getControl('technicianCompanyName')">
          <input formControlName="technicianCompanyName" pInputText placeholder="Technician Company Name" type="text"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-6 sm:col-12 col-12">
        <tj-field [control]="getControl('technicianPersonnelCode')">
          <input formControlName="technicianPersonnelCode" pInputText placeholder="Technician Code" type="text"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-6 sm:col-12 col-12">
        <tj-field [control]="getControl('startDate')">
          <tj-datepicker [onlyFuture]="false" formControlName="startDate" placeholder="Start Date"></tj-datepicker>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-6 sm:col-12 col-12">
        <tj-field [control]="getControl('endDate')">
          <tj-datepicker [onlyFuture]="false" formControlName="endDate" placeholder="End Date"></tj-datepicker>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-6 sm:col-12 col-12">
        <tj-field [control]="getControl('ticketId')">
          <input formControlName="ticketId" pInputText placeholder="Ticket #" type="number"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-6 sm:col-12 col-12">
        <tj-field [control]="getControl('status')">
          <p-dropdown [options]="configService.payFormStatuses" formControlName="status"
                      placeholder="Status"></p-dropdown>
        </tj-field>
      </div>
    </div>
  </div>
</div>
