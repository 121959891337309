import {AddressDetails} from '../Address.model';

export class RouteRequestModel {
  technicianId: number;
  email: string;
  date: Date;
  startTime: Date;
  routes: RouteItemModel[];
}

export class RouteItemModel {
  name?: string;
  address: AddressDetails;
  addressUrl: string;
  serviceTime: number;
  duration: number = 0;
  distance: number = 0;
  ticketId: number;
}

