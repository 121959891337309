<div>
  <span (click)="onRemoveFilter(filter.key)"
        *ngFor="let filter of payFormSearchFilter | keys"
        class="search-label">
    <ng-container [ngSwitch]="filter.key">
      <span *ngSwitchCase="'technicianId'">{{filterNames[filter.key]}}: {{filter.value}}</span>
      <span *ngSwitchCase="'ticketId'">{{filterNames[filter.key]}}: {{filter.value}}</span>
      <span *ngSwitchCase="'status'">{{filterNames[filter.key]}}: {{filter.value}}</span>
      <span *ngSwitchCase="'startDate'">{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}</span>
      <span *ngSwitchCase="'endDate'">{{filterNames[filter.key]}}: {{filter.value | date: config.dateFormat}}</span>
      <span *ngSwitchDefault>{{filterNames[filter.key]}}: {{filter.value}}</span>
    </ng-container>
    <i class="fi-x"></i>
  </span>
</div>
