import {PayType} from '../../main/shared/enums/pay.type';
import {Area} from './Area.model';
import {WeeklyOff} from './WeeklyOff.model';
import {Vacation} from './Vacation.model';

export class TechnicianMinimalListItem {
  id: number;
  name: string;
  email: string;
  active: boolean;
  payType: PayType;
  percent: number;
  percentType: string;
  unit: number;
  speciality: string;
  areas: Area[];
  showOnCalendar: boolean;
  companyName: string;
  personnelCode: string;
  maxStops: number;
  maxWorkHours: number;
  weeklyOffs: WeeklyOff[];
  vacations: Vacation[];
}
