import {ContentType} from './content.type';

export function download(value: Blob, name: string, contentType: ContentType = ContentType.APPLICATION_PDF) {
  const newBlob = new Blob([value], {type: contentType});
  const data = window.URL.createObjectURL(newBlob);
  const link = document.createElement('a');
  link.href = data;
  link.download = name;
  // this is necessary as link.click() does not work on the latest firefox
  link.dispatchEvent(new MouseEvent('click', {bubbles: true, cancelable: true, view: window}));
  setTimeout(function () {
    // For Firefox it is necessary to delay revoking the ObjectURL
    window.URL.revokeObjectURL(data);
    link.remove();
  }, 100);
}

export function toDataURL(urlString: string) {
  return fetch(urlString, {cache: 'no-store'})
    .then(response => response.blob())
    .then(blob => new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.onloadend = () => resolve(reader.result);
      reader.onerror = reject;
      reader.readAsDataURL(blob);
    }));
}

export function transformDistance(value: number, unit: 'km' | 'mi'): string {
  if (value === null || value === undefined || isNaN(value) || value < 0) {
    return 'Invalid distance';
  }

  if (unit === 'km') {
    const kilometers = value / 1000; // convert meters to kilometers
    return kilometers.toFixed(2) + ' km';
  } else if (unit === 'mi') {
    const miles = value / 1609.34; // convert meters to miles
    return miles.toFixed(2) + ' mi';
  } else {
    return 'Invalid unit';
  }
}

export function transformDuration(value: number): string {
  if (value === null || value === undefined || isNaN(value) || value < 0) {
    return 'Invalid duration';
  }

  const hours = Math.floor(value / 3600);
  const minutes = Math.floor((value % 3600) / 60);

  let result = '';
  if (hours > 0) {
    result += hours + ' hour' + (hours > 1 ? 's' : '') + ' ';
  }

  if (minutes > 0) {
    result += minutes + ' minute' + (minutes > 1 ? 's' : '');
  }

  return result.trim();
}

export function dateToSeconds(date: Date): number {
  let hours = date.getHours();
  let minutes = date.getMinutes();
  return (hours * 3600) + (60 * minutes);
}
