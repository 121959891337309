<div [formGroup]="currentFormGroup" class="grid align-items-center">
  <div class="col-12">
    <div class="grid justify-content-start">
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcId">
          <input pInputText type="text"
                 placeholder="Invoice #"
                 id="id"
                 formControlName="id"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcTicketId">
          <input pInputText type="text"
                 placeholder="Ticket #"
                 id="ticketId"
                 formControlName="ticketId"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcExternalReferenceCode">
          <input pInputText type="text"
                 placeholder="WO/PO/Sales"
                 id="externalReferenceCode"
                 formControlName="externalReferenceCode"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcContact">
          <input pInputText type="text"
                 placeholder="Contact"
                 id="contact"
                 formControlName="contact"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcServiceDate">
          <tj-datepicker [onlyFuture]="false"
                         [placeholder]="'Service date'"
                         id="serviceDate"
                         formControlName="serviceDate"
                         [ngClass]="fcServiceDate.value ? 'selected-item-bold' : ''"></tj-datepicker>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcComment">
          <input pInputText type="text"
                 placeholder="Comment"
                 id="comment"
                 formControlName="comment"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcAddress">
          <input pInputText type="text"
                 placeholder="Address"
                 id="address"
                 formControlName="address"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcIssueDate">
          <tj-date-range-picker formControlName="issueDate" dataType="string"
                                placeholder="Invoice date or range"></tj-date-range-picker>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcTechnician">
          <tj-technician-combobox formControlName="technician"></tj-technician-combobox>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcCustomer">
          <input pInputText type="text"
                 placeholder="Customer"
                 id="customer"
                 formControlName="customer"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcPartner">
          <input pInputText type="text"
                 placeholder="3rd Party - None Account"
                 formControlName="partner"/>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-12 col-12">
        <tj-field [control]="fcStatus">
          <p-dropdown [options]="configService.invoiceStatuses$ | async" placeholder="Status"
                      formControlName="status"></p-dropdown>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-4 col-12">
        <tj-field [control]="fcPartnerType">
          <tj-partner-type-combobox formControlName="partnerType" ></tj-partner-type-combobox>
        </tj-field>
      </div>
      <div class="lg:col-6 md:col-6 sm:col-8 col-12" *tjHasRole="'PARTNER_VIEW'">
        <tj-field [control]="fcPartnerId">
          <tj-partner-combobox formControlName="partnerId" [initPrivatePartner]="false"></tj-partner-combobox>
        </tj-field>
      </div>
      <div class="lg:col-2 md:col-3 sm:col-4 col-12">
        <tj-field>
          <p-dropdown [options]="nettermsTypes$ | async" placeholder="Payment Term" formControlName="netTermType"></p-dropdown>
        </tj-field>
      </div>
    </div>
  </div>
</div>
