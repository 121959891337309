<div class="flex flex-wrap">
  <div class="col-12">
    <p
      class="text-xl info-red">{{selectedTechnician.address.addressFormatted + ' | ' + selectedTechnician.email + ' | ' + selectedTechnician.phoneNumber }}</p>
  </div>
</div>
<div class="flex flex-wrap">
  <div class="col-12">
    <div class="flex flex-wrap">
      <div class="col-2"><b>Area / Address</b></div>
      <div class="col-3"><b>Service time</b></div>
      <div class="col-2"><b>Tags</b></div>
      <div class="col-2"><b>Time needed</b></div>
      <div class="col-3 text-right"><b>Additional technicians</b></div>
    </div>

    <div *ngFor="let event of selectedStops; let last = last" class="flex flex-wrap" (click)="selectStop(event)">
      <div class="col-2">
        <small *ngIf="event.customer.address.tag; else customerAddress">{{ event.customer.address.tag }}</small>
        <ng-template #customerAddress>
          <small>{{ event.customer.address.addressFormatted }}</small>
        </ng-template>
      </div>
      <div class="col-3">
        <small class="p-text-secondary">{{ event.serviceTimeStart | date: env.dateTimeOnly }}
          - {{ event.serviceTimeEnd | date: env.dateTimeOnly }}</small>
      </div>
      <div class="col-2 flex flex-wrap gap-1">
        <p-chip  *ngFor="let op of event.tags" label="{{ op.label }}"></p-chip>
      </div>
      <div class="col-2">
        <div class="level-wrapper">
          <div >
            <small class="p-text-secondary level-{{getJobLevel(event)}}">{{ event.timeNeeded }}h</small>
          </div>
        </div>
      </div>
      <div class="col-3">
        <div class="flex justify-content-end">
          <p-avatarGroup>
            <ng-container *ngFor="let addTech of event.technicians">
              <!--not show head tech in as additional tech-->
              <ng-container *ngIf="addTech.id !== selectedTechnician.id">
                <p-avatar shape="circle" [pTooltip]="addTech.personnelCode ? addTech.personnelCode : addTech.name" tooltipPosition="top" styleClass="m-1">
                  {{ addTech.name[0] }}
                </p-avatar>
              </ng-container>
            </ng-container>
          </p-avatarGroup>
        </div>
      </div>
      <div class="col-12 text-right">
        <a [routerLink]="['/tickets', event.id]" class="list-link">{{ 'Ticket #' + event.id }}</a>
      </div>
      <div class="col-12" *ngIf="event.appointmentDetails">
        <span class="info-red">{{ event.appointmentDetails }}</span>
      </div>
      <div class="col-12" *ngIf="!last">
        <p-divider></p-divider>
      </div>
    </div>
  </div>
</div>

