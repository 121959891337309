import {Injectable} from '@angular/core';
import {environment} from '../../../../environments/environment';

@Injectable()
export class GalleryService {

  imgproxyEndpoint: string = environment.imgproxyEndpoint;

  public getImagePath(imagePath: string, width = 0, height = 0): string {
    return this.imgproxyEndpoint + `/w:${width}/h:${height}/plain/s3://tj-attachments/${imagePath}`;
  }

  public getImageZipPath(imagePath: string): string {
    return this.imgproxyEndpoint + `/plain/s3://tj-attachments/${imagePath}`;
  }

}
