export class Messages {

  public static NO_ACCESS = 'No access';

  public static TECHNICIAN_GET_ERROR = 'Could not load selected technician';
  public static TECHNICIAN_CREATED = 'Technician successfully created';
  public static TECHNICIAN_UPDATED = 'Technician successfully updated';
  public static TECHNICIAN_DEACTIVATED = 'Technician successfully deactivated';
  public static TECHNICIAN_RECOVERED = 'Technician successfully recovered';
  public static TECHNICIANS_GET_ERROR = 'Could not load technicians';

  public static CONFIG_CREATED = 'Config successfully updated';

  public static STATUS_UPDATED = 'Status was updated';
  public static STATUS_NOT_UPDATED = 'Status was not updated.';

  public static PARTNER_GET = 'Could not load selected partner';
  public static PARTNER_GET_ALL = 'Could not load partners';
  public static PARTNER_DEACTIVATED = 'Partner successfully deactivated';
  public static PARTNER_RECOVERED = 'Partner successfully recovered';
  public static PARTNER_CREATED = 'Partner successfully created';
  public static PARTNER_UPDATED = 'Partner successfully updated';
  public static PARTNER_SAVED = 'Partner successfully saved';

  public static PAY_FORM_GET = 'Could not load selected pay-form';
  public static PAY_FORM_GET_ALL = 'Could not load pay forms';
  public static PAY_FORM_CREATED = 'Pay form saved';
  public static PAY_FORM_UPDATED = 'Pay form successfully updated';
  public static PAY_FORM_NOT_CREATED = 'Pay form was not created';
  public static PAY_FORM_TRANSFERRED = 'Pay form has been transferred';
  public static PAY_FORM_TRANSFER_MONEY = 'Money is less than or equal 0';
  public static PAY_FORM_DELETED = 'Pay form successfully deleted.';
  public static PAY_FORM_DELETE_ERROR = 'Error during deleting pay form';

  public static TICKET_CREATED = 'Ticket successfully created';
  public static TICKET_PDF_CREATED = 'Ticket PDF has not been created';
  public static TICKET_SAVED = 'Ticket successfully saved';
  public static TICKET_DELETED = 'Ticket successfully deleted';
  public static TICKET_RECOVERED = 'Ticket successfully recovered';
  public static TICKET_UPDATED = 'Ticket successfully updated';
  public static TICKET_SENT = 'Ticket has been sent';
  public static TICKET_SENT_ERROR = 'Ticket has not been sent';
  public static TICKET_SAVED_FAILED = 'Failed to save ticket';
  public static TICKET_DUPLICATED = 'Ticket successfully duplicated';
  public static TICKET_DUPLICATED_FAILED = 'Failed to duplicate the ticket';
  public static TICKET_PARTNER_DETACHED = 'Ticket successfully converted to private';
  public static TICKET_PARTNER_DETACHED_FAILED = 'Failed to convert ticket to private';
  public static TICKET_SAVED_CUSTOMER_NOTIFIED = 'A notification has been sent to the customer';
  public static TICKET_HAS_BEEN_CHANGED = 'This ticket has been changed by another user! Reload the page.';


  public static FILE_SIZE = 'One or more files is too large';
  public static FILE_UPLOADING = 'File has been uploaded successfully';
  public static FILE_REMOVED = 'File was removed successfully';

  public static INVOICE_CREATE = 'Could not create invoice';
  public static INVOICE_ESTIMATED = `Invoice was estimated`;
  public static INVOICE_CANT_PRINT = `Invoice can be printed`;
  public static INVOICE_CANT_CREATE = 'Invoice cannot be created. Error occured.';
  public static ESTIMATE_CANT_CREATE = 'Estimate cannot be created. Error occured';
  public static ESTIMATE_CANT_UPDATED = 'Estimate cannot be updated. Error occured';
  public static ESTIMATE_UPDATED = 'Estimate was updated';
  public static INVOICE_CANT_CREATE_NOID = 'Can\'t created invoice. No ticket id provided';
  public static ESTIMATE_CANT_CREATE_NOID = 'Can\'t created estimate. No ticket id provided';
  public static INVOICE_EMAIL_SENT = 'Invoice has been successfully sent via email';
  public static INVOICE_EMAIL_SENT_ERROR = 'Cannot sent the email';
  public static INVOICE_EMAIL_RESEND = 'Invoice reminders were sent';
  public static INVOICE_EMAIL_CANT_RESEND = 'Cannot sent the reminders';


  public static CUSTOMER_SAVED = 'Customer successfully saved';

  public static JOB_GET_ERROR = 'Cannot load job';
  public static TICKET_GET_ALL_ERROR = 'Cannot load jobs';
  public static JOB_CREATED = 'Ticket successfully created';
  public static JOB_DELETED = 'Ticket successfully deleted';
  public static JOB_DELETED_FAILED = 'Ticket delete failed';
  public static JOB_SAVED = 'Ticket successfully saved';
  public static JOB_SAVED_FAILED = 'Failed to save job';
  public static JOB_COPIED = 'Ticket was copied';
  public static TICKET_INVOICED = `Ticket was invoiced`;
  public static TICKETS_INVOICED = `Ticket(s) has been invoiced`;
  public static UNSAVED_TICKET_INVOICE = `You have unsaved changes. Save the ticket before invoicing.`;
  public static UNSAVED_TICKET_ESTIMATE = `You have unsaved changes. Save the ticket before estimating.`;

  public static TAG_SAVED = 'Tag saved';
  public static TAG_REMOVED = 'Tag removed';
  public static TAG_REMOVE_ERROR = 'Not able to remove the tag';
  public static TAG_SAVED_ERROR = 'Not able to save the tag';
  public static TAG_UPDATED = 'Tag successfully updated';
  public static TAG_CREATED = 'Tag successfully created';

  public static TICKET_STATUS_UPDATED = 'Ticket status successfully changed';
  public static TICKET_STATUS_UPDATED_FAILED = 'Ticket status cannot be changed';
  public static ZIP_CODE_ADD_FAILED = 'Invalid zip code';

  public static ZIP_CODE_IMPORTED = 'Zip codes successfully imported';
  public static ZIP_CODE_EXPORTED = 'Zip codes successfully exported';
  public static ZIP_CODE_UPDATED = 'Zip code successfully updated';
  public static ZIP_CODE_CREATED = 'Zip code successfully created';
  public static ZIP_CODE_INSERTED = 'Zip codes successfully inserted';
  public static ZIP_CODE_DELETED = 'Zip code successfully deleted';

  public static AREA_UPDATED = 'Area successfully updated';
  public static AREA_CREATED = 'Area successfully created';
  public static AREA_DELETED = 'Area successfully deleted';

}
