<p-table *ngIf="routes"
         selectionMode="single"
         (onRowSelect)="view($event.data.id)"
         (onSort)="sort($event)"
         [(selection)]="selectedRoutes"
         [autoLayout]="true"
         [customSort]="true"
         [responsive]="true"
         [value]="routes">
  <ng-template pTemplate="header">
    <tr>
      <th style="width: 4rem">
        <p-tableHeaderCheckbox></p-tableHeaderCheckbox>
      </th>
      <th pSortableColumn="id">Route
        <p-sortIcon field="id"></p-sortIcon>
      </th>
      <th pSortableColumn="technicianId">Technician
        <p-sortIcon field="technicianId"></p-sortIcon>
      </th>
      <th pSortableColumn="date">Date
        <p-sortIcon field="date"></p-sortIcon>
      </th>
    </tr>
  </ng-template>

  <ng-template let-route pTemplate="body">
    <tr [pSelectableRow]="route">
      <td>
        <p-checkbox [(ngModel)]="selectedRoutes" [value]="route"></p-checkbox>
      </td>
      <td>
        {{route.id}}
      </td>
      <td>
        {{route.technicianName}}
      </td>
      <td>
        {{route.date | date: config.dateFormat}}
      </td>
    </tr>
  </ng-template>
</p-table>
