<div [formGroup]="formGroup">
  <p-panel header="Filters" class="col-12">

      <div class="grid">
        <div class="col-12 text-right" *ngIf="!route">
          <a
            [routerLink]="['/routes']"
            styleClass="text-primary"
            [pTooltip]="'Show previous routes'"
          >
            Saved routes
          </a>
        </div>
        <div class="lg:col-3 col-12">
          <tj-technician-combobox
            formControlName="technicianId"
            placeholder="Select a technician"
            [disabled]="!!route"
            (technicianChange)="selectTechnician($event)"
          ></tj-technician-combobox>
        </div>
        <div class="lg:col-3 col-6">
          <tj-field>
            <tj-datepicker
              [onlyFuture]="false"
              [disabled]="!!route"
              formControlName="date"
              placeholder="MM/DD/YY"
              id="serviceDate"
            ></tj-datepicker>
          </tj-field>
        </div>
        <div class="lg:col-3 col-6">
          <input
            placeholder="Email"
            pInputText
            type="email"
            formControlName="email"
          />
        </div>
        <div class="col flex justify-content-end">
          <div class="mr-1" *ngIf="!route">
            <button (click)="onSave()" pButton [disabled]="formGroup.invalid">
              Save
            </button>
          </div>
          <div class="mr-1" *ngIf="!route">
            <button
              (click)="onCreateAndSend()"
              pButton
              [disabled]="formGroup.invalid"
            >
              Save and Send
            </button>
          </div>
          <div class="" *ngIf="route">
            <button (click)="onSend()" pButton [disabled]="formGroup.invalid">
              Send
            </button>
          </div>
        </div>
      </div>

  </p-panel>

  <ng-container *tjHasRole="'TICKET_COMMENT_ON_TIME_NEEDED_EDIT'">
    <div class="col-12" *ngIf="!route?.id && currentDateRouteId">
      <p class="text-red-400">
        You have a planned route for today.
        <a [routerLink]="['/routes', currentDateRouteId]">Check here</a>
      </p>
    </div>
  </ng-container>
  <div class="card col-12">
    <div class="grid">
      <div class="col-12 lg:col-7">
        <div class="col-12" *ngIf="routeDetails && !route">
          <h4>Duration {{ getTotalDuration() | duration }}</h4>
          <h4>Distance {{ getTotalDistance() | distance : "mi" }}</h4>
        </div>
        <div class="col-12">
          <div>
            <div class="grid">
              <div class="col-1">
                <div class="marker-block">
                  <img
                    class="marker-img"
                    src="assets/images/flag_start.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="col">
                <tj-address-search
                  (addressChange)="startAddressChange($event)"
                  [address]="startAddress"
                  [disabled]="!!route"
                  [showMap]="false"
                ></tj-address-search>
                <label>
                  <input
                    type="checkbox"
                    (change)="setEndAddress(!hideEndAddress)"
                    [disabled]="!!route"
                  />
                  Set End Address or Return Route
                </label>
              </div>
            </div>
          </div>
          <div>
            <div class="grid">
              <div class="col-1">
                <div class="marker-block">
                  <img
                    class="marker-img"
                    src="assets/images/flag_end.png"
                    alt=""
                  />
                </div>
              </div>
              <div class="col">
                <tj-address-search
                  (addressChange)="endAddressChange($event)"
                  [address]="endAddress"
                  [disabled]="hideEndAddress || !!route"
                  [showMap]="false"
                ></tj-address-search>
              </div>
              <div class="align-items-end col-12 flex justify-content-end mb-4" *ngIf="legs && !route">
                <div class="mr-3">
                  + {{ getDuration(legs.length - 1) | duration }}
                  <br />
                  + {{ getDistance(legs.length - 1) | distance : "mi" }}
                </div>
                <div>
                  <button
                    pButton
                    icon="pi pi-info"
                    *ngIf="legs"
                    (click)="showLeg(legs.length - 1)"
                    class="p-button-info mt-2"
                  ></button>
                </div>
              </div>
            </div>
          </div>

          <p-table
            (onRowReorder)="onReorder()"
            [value]="directionComponents"
            responsiveLayout="stack"
            styleClass="p-datatable-striped"
          >
            <ng-template pTemplate="header">
              <tr>
                <th>
                  <h6 class="text-center">Order</h6>
                </th>
                <th>
                  <h6 class="text-center">Customer</h6>
                </th>
                <th>
                  <h6 class="text-center">Address</h6>
                </th>
                <th>
                  <h6 class="text-center">Service time</h6>
                </th>
                <th>
                  <h6 class="text-center">Distance / Time</h6>
                </th>
                <th>
                  <h6 class="text-center">Action</h6>
                </th>
              </tr>
            </ng-template>
            <ng-template pTemplate="body" let-address let-i="rowIndex">
              <tr [pReorderableRow]="i">
                <td>
                  <div class="align-items-center flex justify-content-between w-4rem">
                    <div>
                      <span class="pi pi-bars" pReorderableRowHandle></span>
                    </div>
                    <div class="flex flex-column align-items-center">
                      <div class="marker-block">
                        <img
                          class="marker-img"
                          src="assets/images/red-marker.png"
                          alt=""
                        />
                        <span class="marker-text"> {{ address["id"] }}</span>
                      </div>
                      <div>
                        <a
                          [routerLink]="['/tickets', address?.ticketId]"
                          class="font-bold pt-1 list-link ticket-link"
                          *ngIf="address?.ticketId"
                          >{{ address.ticketId }}</a
                        >
                      </div>
                    </div>
                  </div>
                </td>
                <td>
                  <span class="p-column-title">Customer</span>
                  <tj-field>
                    <input
                      pInputText
                      type="text"
                      [formControl]="address['customerFormControl']"
                      [disabled]="!!route"
                    />
                  </tj-field>
                </td>
                <td>
                  <span class="p-column-title">Address</span>
                  <tj-address-search
                    (addressChange)="onAddressChange($event, i)"
                    [disabled]="!!route"
                    [address]="address['address']"
                    [showMap]="false"
                  ></tj-address-search>
                </td>
                <td>
                  <span class="p-column-title">Start time</span>
                  <div class="service-times">
                    <span class="pt-1" *ngIf="address?.serviceTimeStart">{{
                      address.serviceTimeStart | date : config.dateTimeOnly
                    }}</span>
                    <span
                      *ngIf="address?.serviceTimeStart || address?.serviceTimeEnd"
                    >
                      -
                    </span>
                    <span class="pt-1" *ngIf="address?.serviceTimeStart">{{
                      address.serviceTimeEnd | date : config.dateTimeOnly
                    }}</span>
                  </div>
                </td>
                <td>
                  <span class="p-column-title">Distance / Time</span>
                  <div>
                    <ng-container *ngIf="legs && legs[i] && i != legs.length - 1">
                      <span>+ {{ getDistance(i) | distance : "mi" }}</span>
                      <br />
                      <span>+ {{ getDuration(i) | duration }}</span>
                    </ng-container>
                  </div>
                </td>
                <td>
                  <span class="p-column-title">Action</span>
                  <div class="flex justify-content-end">
                    <ng-container *tjHasRole="'TICKET_EDIT'">
                      <button
                        pButton
                        icon="pi pi-pencil"
                        *ngIf="address?.ticketId && route?.id"
                        (click)="editServiceDates(address)"
                        class="mr-2"
                      ></button>
                    </ng-container>
                    <button
                      pButton
                      icon="pi pi-trash"
                      *ngIf="!route?.id"
                      (click)="removeStop(i)"
                      class="p-button-danger mr-2"
                    ></button>
                    <button
                      pButton
                      icon="pi pi-copy"
                      [cdkCopyToClipboard]="getAddress(i)"
                      (cdkCopyToClipboardCopied)="onCopySuccess($event)"
                      class="mr-2"
                    ></button>
                    <button
                      pButton
                      icon="pi pi-external-link"
                      (click)="openMap(i)"
                      class="mr-2"
                    ></button>
                    <button
                      pButton
                      icon="pi pi-info"
                      *ngIf="legs"
                      (click)="showLeg(i)"
                      class="p-button-info"
                    ></button>
                  </div>
                </td>
              </tr>
            </ng-template>
          </p-table>
        </div>
        <div class="col-12 text-right" *ngIf="!route">
          <button
            (click)="onAddAddress()"
            pButton
            class="p-button-danger mr-1"
            icon="pi pi-plus"
          ></button>
          <button (click)="showRoute()" pButton>Show route</button>
        </div>
      </div>
      <div class="col-12 lg:col-5">
        <p-gmap
          #gmap
          [options]="mapDefaultConfig"
          [overlays]="waypoints"
          [style]="{ width: '100%', height: '600px' }"
        ></p-gmap>
      </div>
    </div>
  </div>
</div>

<p-sidebar
  [(visible)]="visibleLegSidebar"
  position="right"
  [baseZIndex]="10000"
  styleClass="p-sidebar-md"
>
  <tj-route-directions
    [legs]="[selectedLeg]"
    *ngIf="selectedLeg"
  ></tj-route-directions>
</p-sidebar>
