import {TechnicianMinimalListItem} from '../../../../api/model/TechnicianMinimalListItem';
import {Component, EventEmitter, forwardRef, Input, OnInit, Output} from '@angular/core';
import {ControlValueAccessor, NG_VALUE_ACCESSOR} from '@angular/forms';
import {ConfigService} from '../../../../core/services';

@Component({
  selector: 'tj-technician-combobox',
  templateUrl: './technician-combobox.component.html',
  styleUrls: ['./technician-combobox.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      useExisting: forwardRef(() => TechnicianComboboxComponent),
      multi: true
    }
  ]
})
export class TechnicianComboboxComponent implements OnInit, ControlValueAccessor {

  protected readonly TechnicianMinimalListItem = TechnicianMinimalListItem;

  @Output() technicianChange = new EventEmitter();

  @Input() disabled: boolean;

  @Input() showMarker = false;

  @Input() showSpeciality = false;

  @Input() areas: string[] = [];

  allTechnicians: TechnicianMinimalListItem[] = [];
  filteredTechnicians;

  private onTouchedCallback: Function;
  private onChangeCallback: Function;
  private innerValue = null;

  constructor(public configService: ConfigService) {
  }

  get value(): any {
    return this.innerValue;
  }

  set value(v: any) {
    this.innerValue = v;
    if (this.onChangeCallback) {
      const finalValue = v && v.id;
      this.onChangeCallback(finalValue);
    }
  }

  ngOnInit() {
    this.configService.technicians$.subscribe(value => {
      this.allTechnicians = value;
    });
  }

  onTechnicianChange(value) {
    this.technicianChange.emit(value);
  }

  onBlur() {
    this.onTouchedCallback();
  }

  setDisabledState(disabled: boolean) {
    this.disabled = disabled;
  }

  writeValue(id: any): void {
    this.innerValue = this.allTechnicians.find((p: TechnicianMinimalListItem) => p.id === id);
  }

  registerOnChange(fn: any) {
    this.onChangeCallback = fn;
  }

  registerOnTouched(fn: any) {
    this.onTouchedCallback = fn;
  }

  onFilterSuggestions(event) {
    const query = event.query;
    const values = this.allTechnicians;
    const filtered: any[] = [];
    for (const value of values) {
      if (value.name.toLowerCase().indexOf(query.toLowerCase()) > -1) {
        filtered.push(value);
      }
    }
    if (this.configService.isEnabledTerritory()) {
      const areas = this.areas || [];
      filtered.sort((techA, techB) => this.sortTechniciansByAreaCode(techA, techB, areas));
    }
    this.filteredTechnicians = filtered;
  }

  hasAreaCode(value: TechnicianMinimalListItem) {
    const areas = this.areas || [];
    return value.areas?.find(area => areas.find(searchArea => area.code === searchArea));
  }

  // Function to sort technicians based on area code
  private sortTechniciansByAreaCode(techA, techB, targetAreaCodes) {
    // Find the area codes for each technician
    const techAHasSpecifiedAreaCode = targetAreaCodes?.some(code => techA.areas?.some(area => area.code === code));
    const techBHasSpecifiedAreaCode = targetAreaCodes?.some(code => techB.areas?.some(area => area.code === code));

    // Handle the case when the area code is not found in either technician's areas
    if (!techAHasSpecifiedAreaCode && !techBHasSpecifiedAreaCode) {
      return 0; // No change in order
    } else if (!techAHasSpecifiedAreaCode) {
      return 1; // Move techA to a higher index
    } else if (!techBHasSpecifiedAreaCode) {
      return -1; // Move techB to a higher index
    }

    // Compare the area codes
    // Here, you might need to define the logic for comparing multiple area codes
    // For simplicity, let's assume we compare the first found area code in each technician's list
    const techAFirstAreaCode = techA.areas.find(area => targetAreaCodes.includes(area.code));
    const techBFirstAreaCode = techB.areas.find(area => targetAreaCodes.includes(area.code));
    return techAFirstAreaCode.code.localeCompare(techBFirstAreaCode.code);
  }

  get addStyleClass(): string {
    return this.value ? 'selected-item-bold' : '';
  }

}
