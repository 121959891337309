import {
  AfterViewInit,
  ChangeDetectionStrategy,
  ChangeDetectorRef,
  Component,
  OnDestroy,
  OnInit,
  ViewChild
} from '@angular/core';
import {CalendarOptions, EventClickArg} from '@fullcalendar/core';
import dayGridPlugin from '@fullcalendar/daygrid';
import {ApiService} from '../../../api/service/api.service';
import {DailyTicketsModel} from '../../../api/model/scheduler/daily.tickets.model';
import {FullCalendarComponent} from '@fullcalendar/angular';
import * as dayjs from 'dayjs';
import {ConfigService} from '../../../core/services';
import {Technician} from '../../../api/model/Technician.model';
import {Ticket} from '../../../api/model/Ticket.model';
import {CalendarSearchCriteria} from '../service/calendar-search-criteria';
import {CalendarSearchService} from '../service/calendar-search.service';
import {UntypedFormBuilder, UntypedFormGroup} from '@angular/forms';
import {TicketTechnician} from '../../../api/model/TicketTechnicians.model';
import {WeekDays} from '../../shared/enums/weekDays';
import {CalendarTicketModel} from '../../../api/model/scheduler/calendar.ticket.model';
import {BehaviorSubject, Subject} from 'rxjs';
import {debounceTime, takeUntil} from 'rxjs/operators';
import {Vacation} from '../../../api/model/Vacation.model';
import {CalendarTechnicianModel} from '../../../api/model/scheduler/calendar.technician.model';
import {Colors} from '../../../common/colors';

@Component({
  selector: 'tj-calendar',
  templateUrl: './calendar.component.html',
  styleUrls: ['./calendar.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class CalendarComponent implements OnInit, AfterViewInit, OnDestroy {

  @ViewChild(FullCalendarComponent) fullCalendarComponent: FullCalendarComponent;

  calendarOptions: CalendarOptions = {
    plugins: [dayGridPlugin],
    initialView: 'customWeeks',
    firstDay: 1,
    views: {
      customWeeks: {
        type: 'dayGrid',
        duration: { weeks: 1 },
        titleFormat: { year: 'numeric', month: 'long', day: 'numeric' },
      },
    },
    visibleRange: this.getVisibleRange.bind(this),
    headerToolbar: {
      left: '',
      center: '',
      right: '',
    },
    editable: true,
    selectable: true,
    eventClick: this.handleEventClick.bind(this),
  };

  selectedTechnician: Technician;
  selectedDate: Date;
  selectedStops: Ticket[];
  showStopsDialog = false;
  searchCriteria: CalendarSearchCriteria = {} as CalendarSearchCriteria;
  form: UntypedFormGroup;

  private eventsSubject = new BehaviorSubject<any[]>([]);
  readonly events$ = this.eventsSubject.asObservable();
  private readonly destroy$ = new Subject<void>();

  constructor(
    private api: ApiService,
    private calendarSearchService: CalendarSearchService,
    private fb: UntypedFormBuilder,
    public configService: ConfigService,
    private cdr: ChangeDetectorRef
  ) {}

  ngOnInit(): void {
    this.form = this.fb.group({
      ticketId: this.fb.control(this.searchCriteria.ticketId),
      area: this.fb.control(this.searchCriteria.area),
      technician: this.fb.control(this.searchCriteria.technician),
      // hideOthers: this.fb.control(this.searchCriteria.hideOthers),
    });
  }



  ngAfterViewInit(): void {
    this.subscribeSearchCriteria();
    this.events$.pipe(
      takeUntil(this.destroy$)
    ).subscribe(events => {
      const calendarApi = this.fullCalendarComponent.getApi();
      calendarApi.removeAllEvents();
      calendarApi.addEventSource(events);
      this.cdr.detectChanges();
    });
  }

  private getVisibleRange(currentDate: Date) {
    const startOfWeek = dayjs(currentDate).startOf('week').toDate();
    return {
      start: startOfWeek,
      end: dayjs(startOfWeek).add(1, 'weeks').endOf('week').toDate(),
    };
  }

  onSearch(): void {
    this.searchCriteria = this.form.value;
    this.loadEventsForCurrentView();
  }

  prev() {
    const calendarApi = this.fullCalendarComponent.getApi();
    calendarApi.prev();
    this.loadEventsForCurrentView();
  }

  next() {
    const calendarApi = this.fullCalendarComponent.getApi();
    calendarApi.next();
    this.loadEventsForCurrentView();
  }

  private loadEventsForCurrentView() {
    const calendarApi = this.fullCalendarComponent.getApi();
    let startDate = dayjs(calendarApi.view.currentStart).format('YYYY-MM-DD');
    let endDate = dayjs(calendarApi.view.currentEnd).format('YYYY-MM-DD');

    if (this.searchCriteria?.ticketId && this.searchCriteria?.serviceDate && this.searchCriteria?.weekStartDate && this.searchCriteria?.weekEndDate) {
      const serviceDate = this.searchCriteria.serviceDate;
      const weekStartDate = this.searchCriteria.weekStartDate;
      const weekEndDate = this.searchCriteria.weekEndDate;

      startDate = weekStartDate.format('YYYY-MM-DD');
      endDate = weekEndDate.format('YYYY-MM-DD');

      calendarApi.gotoDate(serviceDate.format('YYYY-MM-DD'));
    }

    const searchCriteria = this.form.value;

    this.api.scheduler.findInRange(startDate, endDate, searchCriteria).pipe(
      takeUntil(this.destroy$)
    ).subscribe((dailyTickets = []) => {
      const events = dailyTickets.map(this.mapToEvent.bind(this));
      this.eventsSubject.next(events);
    });
  }

  private mapToEvent(ticket: DailyTicketsModel) {
    const ticketsPerArea: Map<string, number> = this.calculateTicketsPerArea(ticket);
    const sortedTicketsPerArea: any[] = [...ticketsPerArea].sort((a, b) => b[1] - a[1]);

    const found = ticket.stops.some(this.eventMatchesSearchCriteria.bind(this));
    if (!found && this.searchCriteria.hideOthers) {
      return null;
    }

    const techArr: TicketTechnician[] = this.extractTechnicians(ticket);

    return {
      title: ticket.technician.personnelCode || ticket.technician.name,
      date: ticket.date,
      textColor: Colors.BLACK,
      backgroundColor: this.getBgColor(ticket.stops.length, ticket.technician, ticket.date),
      borderColor: this.getBorderColor(ticket.stops),
      classNames: [found ? 'border-2' : 'border-0', 'p-2'],
      extendedProps: {
        date: ticket.date,
        technician: ticket.technician,
        technicians: techArr,
        ticketsPerArea: sortedTicketsPerArea,
        stops: ticket.stops,
        expanded: false,
        stopsCount: ticket.stops.length
      }
    };
  }

  private calculateTicketsPerArea(ticket: DailyTicketsModel): Map<string, number> {
    const ticketsPerArea: Map<string, number> = new Map();
    ticket.stops.forEach(stop => {
      const areas = stop.customer.address?.areas || [{'code': stop.customer.address.addressFormatted}];
      areas.forEach(area => {
        const areaCode = area.code;
        if (areaCode) {
          const count = ticketsPerArea.get(areaCode) || 0;
          ticketsPerArea.set(areaCode, count + 1);
        }
      });
    });
    return ticketsPerArea;
  }

  private extractTechnicians(ticket: DailyTicketsModel): TicketTechnician[] {
    const techSet: Map<number, TicketTechnician> = new Map();
    ticket.stops.forEach(stop => stop.technicians.forEach(t => {
      if (ticket.technician.id !== t.id) {
        techSet.set(t.id, t);
      }
    }));
    return Array.from(techSet.values());
  }

  // eventMatchesSearchCriteria(ticket: CalendarTicketModel): boolean {
  //   const findByArea = this.searchCriteria.area
  //     ? this.searchCriteria.area.some(area => ticket.customer.address.tag.includes(area.toString()))
  //     : true;

  //   const findByTicket = this.searchCriteria.ticketId
  //     ? ticket.id === this.searchCriteria.ticketId
  //     : true;

  //   const findByTech = this.searchCriteria.technician
  //     ? ticket.technicians.some(it => {
  //         const matchesByCode = it.personnelCode
  //           ? this.searchCriteria.technician!.includes(Number(it.personnelCode))
  //           : false;
  //         const matchesByName = this.searchCriteria.technician!.includes(Number(it.name));
  //         return matchesByCode || matchesByName;
  //       })
  //     : true;

  //   return findByArea && findByTicket && findByTech;
  // }

  eventMatchesSearchCriteria(ticket: CalendarTicketModel): boolean {
    const findByArea = !this.searchCriteria.area || this.searchCriteria.area?.some(area => {
      return ticket.customer.address?.areas?.find(ticketArea => ticketArea.id === area);
    });

    const findByTicket = !this.searchCriteria.ticketId || ticket.id === this.searchCriteria.ticketId;

    const findByTech = !this.searchCriteria.technician || ticket.technicians.some(it => {
      const matchesByCode = it.personnelCode
        ? this.searchCriteria.technician!.includes(Number(it.personnelCode))
        : false;
      const matchesByName = this.searchCriteria.technician!.includes(Number(it.name));
      return matchesByCode || matchesByName;
    });

    return findByArea || findByTicket || findByTech;
  }



  private handleEventClick(clickInfo: EventClickArg) {
    this.selectedStops = clickInfo.event.extendedProps['stops'];
    this.selectedTechnician = clickInfo.event.extendedProps['technician'] as Technician;
    this.selectedDate = clickInfo.event.extendedProps['date'];

    this.showStopsDialog = true;
    this.cdr.detectChanges();
  }

  getBgColor(stopCount: number, technician: CalendarTechnicianModel, eventDate: Date | string): string {
    const hasVacation = this.hasCurrentDateVacation(eventDate, technician.vacations);
    const hasDayOff = technician.weeklyOffs.some(weeklyOff =>
      this.isMatchingDate(eventDate, weeklyOff.weekDay) && weeklyOff.timeOff?.wholeDay || !weeklyOff.timeOff?.timeRange.length
    );

    if (hasVacation || hasDayOff) {
      return Colors.TECHNICIAN_OFF;
    }

    if (stopCount === 0) {
      return Colors.TECHNICIAN_STOPS_COUNT_NONE;
    } else if (stopCount < 4) {
      return Colors.TECHNICIAN_STOPS_COUNT_LOW;
    } else if (stopCount < 7) {
      return Colors.TECHNICIAN_STOPS_COUNT_HIGH;
    }

    return Colors.TECHNICIAN_STOPS_COUNT_CRITICAL;
  }


  hideDialog() {
    this.showStopsDialog = false;
  }

  get allTechnicians(): string {
    const events = this.fullCalendarComponent.getApi().getEvents();
    if (!events) { return ''; }

    const technicians = events.reduce<string[]>((acc, event) => {
      const techs = (event.extendedProps.technicians as TicketTechnician[] || [])
        .map(tech => tech.personnelCode ? tech.personnelCode : tech.name);
      return acc.concat(techs);
    }, []);

    return technicians.join(', ');
  }

  private subscribeSearchCriteria() {
    this.calendarSearchService.getSearchCriteria()
      .pipe(debounceTime(300), takeUntil(this.destroy$))
      .subscribe((criteria) => {
        this.searchCriteria = criteria;
        this.loadEventsForCurrentView();
      });
  }



  formatTime(time: Date | string): string {
    const dateObj = typeof time === 'string' ? new Date(time) : time;
    return dateObj.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
  }

  isCurrentDateWithinVacation(eventDate: Date | string, startDate: string | Date, endDate: string | Date): boolean {
    const event = dayjs(eventDate).startOf('day');
    const start = dayjs(startDate).startOf('day');
    const end = dayjs(endDate).endOf('day');
    return (event.isAfter(start) || event.isSame(start)) &&
           (event.isBefore(end) || event.isSame(end));
  }

  isMatchingDate(eventDate: Date | string, weekDay: WeekDays): boolean {
    const eventDay = dayjs(eventDate).day();
    const daysOfWeek = ['SUNDAY', 'MONDAY', 'TUESDAY', 'WEDNESDAY', 'THURSDAY', 'FRIDAY', 'SATURDAY'];
    return daysOfWeek[eventDay] === weekDay;
  }

  hasCurrentDateVacation(eventDate: Date | string, vacations: Vacation[]): boolean {
    return vacations.some(vacation => this.isCurrentDateWithinVacation(eventDate, vacation.startDate, vacation.endDate));
  }

  getTechnicianInfo(technician: Technician): string {
    const { name, phoneNumber, email, address } = technician;
    return `Name: ${name}\nPhone: ${phoneNumber}\nEmail: ${email}\nAddress: ${address.country} ${address.city} ${address.street}`;
  }

  ngOnDestroy(): void {
    this.destroy$.next();
    this.destroy$.complete();
  }

  private getBorderColor(stops: CalendarTicketModel[]) {
    const areas = this.form.get('area').value as number[];
    const ticketId = +this.form.get('ticketId').value;

    let matchByArea;
    if (areas) {
      matchByArea = stops.some(ticket => {
        return ticket.customer.address?.areas?.some(area => areas.indexOf(area.id) !== -1);
      });
    }

    let matchByTicket;
    if (ticketId) {
      matchByTicket = stops.some(ticket => {
        return ticket.id === ticketId;
      });
    }

    if (matchByArea || matchByTicket) {
      return 'red';
    }

    return 'transparent';
  }
}
