import {Injectable} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ListSearchService} from '../../shared/services/list-search.service';
import {TechnicianSearchFilterModel} from '../models/technician-search-filter.model';

@Injectable()
export class TechnicianSearchService extends ListSearchService<TechnicianSearchFilterModel> {

  constructor(route: ActivatedRoute,
              router: Router) {
    super(route, router);
  }

  searchByFormValue(formValue) {
    const filter: TechnicianSearchFilterModel = {
      name: formValue.name,
      personnelCode: formValue.personnelCode,
      companyName: formValue.companyName,
      email: formValue.email,
      phone: formValue.phone,
      deleted: formValue.deleted,
    } as TechnicianSearchFilterModel;
    this.search(filter);
  }

  getSearchUrl() {
    return ['/technicians'];
  }

  getSearchConfig() {
    return {
      name: {
        searchQueryPrefix: 'firstName~'
      },
      personnelCode: {
        searchQueryPrefix: 'personnelCode~'
      },
      companyName: {
        searchQueryPrefix: 'companyName~'
      },
      email: {
        searchQueryPrefix: 'email~'
        // searchQueryPrefix: 'contactPersons.contacts.type:\'EMAIL\',contactPersons.contacts.value~'
      },
      phone: {
        searchQueryPrefix: 'phoneNumber~'
        // searchQueryPrefix: 'contactPersons.contacts.type:\'PHONE\',contactPersons.contacts.value~'
      },
      deleted: {
        searchQueryPrefix: 'active!='
      }
    };
  }
}
