import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ContactPerson} from '../../../../api/model/ContactPerson.model';
import {Invoice} from '../../../../api/model/Invoice.model';
import {ConfigService, TjKeycloakService} from '../../../../core/services';
import {ContactType} from '../../../shared/enums/contact.type';
import {ContactInfoList} from '../../../../api/model/ContactValueList.model';
import {Dropdown} from 'primeng/dropdown';
import {Router} from '@angular/router';
import {PaymentType} from '../../../../api/status/payment.type';
import {InvoiceService} from '../../service/invoice.service';
import {ConfirmationService} from 'primeng/api';

@Component({
  selector: 'tj-invoice-grid',
  templateUrl: './invoice-grid.component.html',
  styleUrls: ['./invoice-grid.component.scss']
})
export class InvoiceGridComponent implements OnInit {

  @Input() invoice: Invoice;

  @Input() forPrint = false;

  @Output() downloadPDFReport: EventEmitter<any> = new EventEmitter<any>();

  @Output() printReport: EventEmitter<any> = new EventEmitter<any>();

  @Output() sendEmailEmitter: EventEmitter<any> = new EventEmitter<any>();

  @Output() changeStatusEventEmitter: EventEmitter<any> = new EventEmitter<any>();

  configs;

  mainPartnerContactPerson: ContactPerson;

  partnerInvoiceReceiverContacts: ContactInfoList[];

  constructor(public configService: ConfigService,
              private router: Router,
              private confirmationService: ConfirmationService,
              protected invoiceService: InvoiceService,
              private keycloakServices: TjKeycloakService) {
  }

  ngOnInit() {
    this.configs = this.configService.globalConfigs$.getValue();
    this.setInvoiceContacts();
  }

  setInvoiceContacts() {
    const contactPersons: ContactInfoList[] = [];
    const contactPersonIds = new Set<number>();

    this.invoice?.items.forEach(item => {
      const contactPersonId = item.mainPartnerContactPerson?.id;
      if (contactPersonId !== undefined && !contactPersonIds.has(contactPersonId)) {
        contactPersonIds.add(contactPersonId);
        const newContacts = this.invoice.partner?.getContactPersonsData(item.mainPartnerContactPerson);
        if (newContacts) {
          contactPersons.push(...newContacts);
        }
      }
    });

    this.partnerInvoiceReceiverContacts = contactPersons;
  }

  downloadPDF(): void {
    this.downloadPDFReport.emit();
  }

  print(): void {
    this.printReport.emit();
  }

  sendEmail(): void {
    this.sendEmailEmitter.emit();
  }

  changeStatus($event: any, invoiceStatus: Dropdown) {
    this.changeStatusEventEmitter.emit({$event, invoiceStatus});
  }

  canEditStatus(): boolean {
    return this.keycloakServices.hasRole('INVOICE_EDIT');
  }

  config(code: string): string {
    return this.configs.find(x => x.code === code)?.value;
  }

  toPaymentPage() {
    const toPayment = () => this.router.navigate(['/payment'], {
      queryParams: {itemType: 'invoice', itemId: this.invoice.id},
      queryParamsHandling: 'merge'
    });
    this.showApprovalConfirmationDialog(toPayment);
  }

  private showApprovalConfirmationDialog(cb: () => any) {
    const approvalRequired = this.invoice.partner?.payment?.approvalRequired;
    if (approvalRequired) {
      this.confirmationService.confirm({
        message: 'This account requires approval prior to charging',
        header: 'Confirmation',
        icon: 'pi pi-info-circle',
        key: 'confirm',
        accept: () => cb(),
      });
    } else {
      cb();
    }
  }
}
