import {catchError, map} from 'rxjs/operators';
import {plainToClass} from 'class-transformer';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {Ticket} from '../model/Ticket.model';
import {TicketFilter} from '../model/TicketFilter';
import {Page, PageQuery, SearchQuery, SortQuery} from '../model/shared/Page.model';
import {TicketLine} from '../model/TicketLine.model';
import {CommunicationFilterableService} from '../service/communication-filtarable.service';
import {Partner} from '../model/Partner.model';
import {throwError as observableThrowError} from 'rxjs/internal/observable/throwError';
import {Customer} from '../model/Customer.model';
import {Tag} from '../model/Tag.model';
import {TicketInvoice} from '../model/TicketInvoice.model';
import {TicketInvoiceResponse} from '../model/TicketInvoiceResponse.model';
import {ClientSignture} from '../model/ticket/client-signature';
import {TicketsMainStats} from '../model/ticket/stats/tickets.main.stats';
import {TicketPDFOptions} from '../status/TicketPDFOptions';
import {TicketStatus} from '../status/ticketStatus';

@Injectable()
export class TicketApi extends CommunicationFilterableService implements SearchQuery {

  protected apiEndpoint = this.env.apiEndpoint + 'tickets';
  filterMapper = {
    id: 'id:',
    externalReferenceCode: 'externalReferenceCode~',
    customer: 'customer.name~',
    contact: 'partner.contactPersons.contacts.value~',
    address: 'partner.address~',
    partnerName: 'partner.name~',
    comment: 'comments.message~',
    technician: 'jobs.jobToTechnicians.technician.name~',
    status: 'status:',
    partnerStatus: 'ticketPartnerStatus:',
    content: 'content~',
    createDate: 'createdAt>='
  };

  findAll(pageQuery: PageQuery, sortQuery: SortQuery, filter: TicketFilter): Observable<Page<TicketLine>> {
    const params = {
      page: pageQuery.number.toString(),
      count: pageQuery.size.toString(),
      order: sortQuery.direction ? sortQuery.direction.toUpperCase() : null,
      sort: sortQuery.property ? sortQuery.property : null,
      search: filter
    };
    const url = this.apiEndpoint;
    return this.requestService
      .get(url, {params})
      .pipe(
        map(response => response as Page<TicketLine>),
        catchError(this.handleError));
  }


  related(ticketId: number, relatedBy: string): Observable<TicketLine[]> {
    const url = this.apiEndpoint + `/${ticketId}/related?by=${relatedBy}`;
    return this.requestService
      .get(url, {})
      .pipe(
        map(response => response.response as TicketLine[]),
        catchError(this.handleError));
  }

  getMainStats(): Observable<TicketsMainStats> {
    const url = this.apiEndpoint + '/stats';
    return this.requestService
      .get(url)
      .pipe(
        map(response => response.response as TicketsMainStats),
        catchError(this.handleError));
  }

  getTicketServiceDate(ticketId: number): Observable<string | Date> {
    const url = `${this.apiEndpoint}/${ticketId}/servicedate`;
    return this.requestService.get(url).pipe(
      catchError(this.handleError));
  }

  findOne(id: number): Observable<Ticket> {
    return this.requestService.get(this.apiEndpoint + '/' + id)
      .pipe(
        map(response => plainToClass(Ticket, response.response as Object) as Ticket),
        catchError(this.handleError)
      );
  }

  findStatuses(): Observable<any> {
    const url = `${this.apiEndpoint}/statuses/list`;
    return this.requestService.get(url).pipe(
      map(response => response),
      catchError(this.handleError),);
  }

  save(ticket: Ticket | any, ticketId?: number, status?: TicketStatus): Observable<Ticket> {
    if (ticketId) {
      return this.update(ticket, ticketId, status);
    }
    return this.create(ticket);
  }

  create(ticket: Ticket): Observable<Ticket> {
    const url = this.apiEndpoint;
    return this.requestService.post(url, ticket)
      .pipe(
        map(response => response.response as Ticket),
        catchError(this.handleError)
      );
  }

  update(ticket: Ticket, ticketId?: number, status?: TicketStatus): Observable<Ticket> {
    const url = `${this.apiEndpoint}/${ticketId}`;
    return this.requestService.put(url, ticket, {'status': status})
      .pipe(
        map(response => {
          return response.response as Ticket;
        }),
        catchError(this.handleError)
      );
  }

  updateDates(ticketId: number, startTime: Date, endTime: Date, timeNeeded: number): Observable<Ticket> {
    const url = `${this.apiEndpoint}/${ticketId}/serviceDates`;
    return this.requestService.patch(url, {
      startTime,
      endTime,
      timeNeeded,
    }).pipe(
      catchError(this.handleError)
    );
  }

  delete(ticketId: number): Observable<void> {
    return this.requestService.remove(this.apiEndpoint + '/' + ticketId).pipe(
      map(
        (response) => {
        }
      ),
      catchError(this.handleError)
    );
  }

  recover(ticketId: number): Observable<void> {
    const url = `${this.apiEndpoint}/${ticketId}/recover`;
    return this.requestService.put(url, {}).pipe(
      map(
        (response) => {
        }
      ),
      catchError(this.handleError)
    );
  }

  invoice(ticketId: number, ticketInvoice: TicketInvoice): Observable<TicketInvoiceResponse> {
    const url = `${this.apiEndpoint}/${ticketId}/invoice`;
    return this.requestService.put(url, ticketInvoice)
      .pipe(
        map(response => response.response as TicketInvoiceResponse),
        catchError(this.handleError)
      );
  }

  updateInvoice(ticketId: number, ticketInvoice: TicketInvoice): Observable<TicketInvoiceResponse> {
    const url = `${this.apiEndpoint}/${ticketId}/invoice`;
    return this.requestService.patch(url, ticketInvoice)
      .pipe(
        map(response => response.response as TicketInvoiceResponse),
        catchError(this.handleError)
      );
  }

  estimate(ticketId: number, ticketInvoice: TicketInvoice): Observable<TicketInvoiceResponse> {
    const url = `${this.apiEndpoint}/${ticketId}/estimate`;
    return this.requestService.put(url, ticketInvoice)
      .pipe(
        map(response => response.response as TicketInvoiceResponse),
        catchError(this.handleError)
      );
  }

  updateEstimate(ticketId: number, ticketInvoice: TicketInvoice): Observable<TicketInvoiceResponse> {
    const url = `${this.apiEndpoint}/${ticketId}/estimate`;
    return this.requestService.patch(url, ticketInvoice)
      .pipe(
        map(response => response.response as TicketInvoiceResponse),
        catchError(this.handleError)
      );
  }

  getLastSignature(ticketId: number): Observable<ClientSignture> {
    const url = `${this.apiEndpoint}/${ticketId}/signature`;
    return this.requestService.get(url, {background: true})
      .pipe(
        map(response => ClientSignture.buildFromBase64String(response.response.signature)),
        catchError(this.handleError)
      );
  }

  downloadPDF(ticketId: number, option: string): Observable<Blob> {
    const url = this.apiEndpoint + '/download';
    return this.requestService.download(url, {
      id: ticketId,
      option,
    }).pipe(
      map(response => response),
      catchError(this.handleError));
  }

  updatePartner(ticketId: number, partner: Partner): Observable<any> {
    const url = `${this.apiEndpoint}/${ticketId}/partner`;
    return this.requestService.patch(url, {
      ticketId,
      partner
    }).pipe(
      map(response => plainToClass(Partner, response.response)),
      catchError(err => {
        this.notificationService.error(err.error.message);
        return observableThrowError(err);
      }));
  }

  detachPartner(ticketId: number): Observable<void> {
    const url = `${this.apiEndpoint}/${ticketId}/partner/detach`;
    return this.requestService.patch(url, {}).pipe(
      map(response => response),
      catchError(err => {
        this.notificationService.error(err.error.message);
        return observableThrowError(err);
      }));
  }

  updateCustomer(ticketId: number, customer: Customer): Observable<any> {
    const url = `${this.apiEndpoint}/${ticketId}/customer`;
    return this.requestService.patch(url, customer).pipe(
      map(response => plainToClass(Customer, response.response)),
      catchError(err => {
        this.notificationService.error(err.error.message);
        return observableThrowError(err);
      }));
  }

  changeStatus(id: number, status: string): Observable<void> {
    const url = `${this.apiEndpoint}/status/${id}/${status}`;
    return this.requestService
      .patch(url, {})
      .pipe(map(response => response), catchError(this.handleError));
  }

  reschedule(ticketId: number, reason: string): Observable<void> {
    const url = `${this.apiEndpoint}/${ticketId}/reschedule`;
    return this.requestService
      .patch(url, {reason}).pipe(
        catchError(this.handleError));
  }

  cancel(ticketId: number, reason: string): Observable<void> {
    const url = `${this.apiEndpoint}/${ticketId}/cancel`;
    return this.requestService
      .patch(url, {reason}).pipe(
        catchError(this.handleError));
  }

  addTag(ticketId: number, tagId: number): Observable<void> {
    const ids = {id: tagId};
    const url = `${this.apiEndpoint}/${ticketId}/tags`;
    return this.requestService.put(url, ids);
  }

  removeTag(ticketId: number, tagId: number): Observable<void> {
    const url = `${this.apiEndpoint}/${ticketId}/tags/${tagId}`;
    return this.requestService.remove(url);
  }

  markAsPrinted(ticketId: number) {
    const url = `${this.apiEndpoint}/${ticketId}/print`;
    return this.requestService.put(url, {});
  }

  duplicate(ticketId: number, reason: string) {
    const url = `${this.apiEndpoint}/${ticketId}/duplicate`;
    return this.requestService.post(url, {
      reason
    }).pipe(
      map(response => response),
      catchError(this.handleError));
  }

  duplicatePrivate(ticketId: number, reason: string) {
    const url = `${this.apiEndpoint}/${ticketId}/duplicate/private`;
    return this.requestService.post(url, {
      reason
    }).pipe(
      map(response => response),
      catchError(this.handleError));
  }

  confirmCustomerAddress(ticketId: number, customerAddressConfirm: boolean): Observable<void> {
    const url = `${this.apiEndpoint}/${ticketId}/customer/address/confirm?addressConfirm=${customerAddressConfirm}`;
    return this.requestService.patch(url, {}).pipe(
      map(response => response),
      catchError(err => {
        this.notificationService.error(err.error.message);
        return observableThrowError(err);
      }));
  }

  sendToEmail(ticketId: number, option: TicketPDFOptions, email: string): Observable<void> {
    const url = `${this.apiEndpoint}/${ticketId}/send`;
    return this.requestService.post(url, {
      option,
      email
    }).pipe(
      map(response => response),
      catchError(err => {
        this.notificationService.error(err.error.message);
        return observableThrowError(err);
      }));
  }

  sendToCustomer(ticketId: number, option: TicketPDFOptions, email?: string): Observable<void> {
    const url = `${this.apiEndpoint}/${ticketId}/send/customer`;
    return this.requestService.post(url, {
      option,
      'email': email
    }).pipe(
      map(response => response),
      catchError(err => {
        this.notificationService.error(err.error.message);
        return observableThrowError(err);
      }));
  }

  sendToPartner(ticketId: number, option: TicketPDFOptions): Observable<void> {
    const url = `${this.apiEndpoint}/${ticketId}/send/partner`;
    return this.requestService.post(url, {
      option
    }).pipe(
      map(response => response),
      catchError(err => {
        this.notificationService.error(err.error.message);
        return observableThrowError(err);
      }));
  }

}
